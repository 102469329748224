import {Empty, Table, Typography} from "antd";
import React from "react";

const TableComponent = ({data}) => {
    const columns = [
        {
            title: "Expiration Date",
            dataIndex: "expirationDate",
            key: "expirationDate",
            width: 155,
            sorter: (a, b) => new Date(a.expirationDate) - new Date(b.expirationDate),
            render: (text) => text.split('T')[0], // This will display only the date part

        },

        {
            title: "Premium %",
            dataIndex: "premiumPerc",
            key: "premiumPerc",
            width: 130,
            sorter: (a, b) => parseFloat(a.premiumPerc) - parseFloat(b.premiumPerc),
            defaultSortOrder: 'descend',
            render: (text) => parseFloat(text).toFixed(2),
        },


        {
            title: "Strike",
            dataIndex: "strike",
            key: "strike",
            width: 75,
            sorter: (a, b) => a.strike - b.strike,
             align: 'center',
            onCell: () => ({
                style: {
                    backgroundColor: '#4f4f4f', // Replace with your desired color
                },
            }),
        },
        {
            title: "Last Price",
            dataIndex: "lastPrice",
            key: "lastPrice",
            width: 120,
            sorter: (a, b) => a.lastPrice - b.lastPrice,
            align: 'center',
            onCell: () => ({
                style: {
                    backgroundColor: '#6e6e6e', // Replace with your desired color
                },
            }),
        },

        {
            title: "Bid",
            dataIndex: "bid",
            key: "bid",
            width: 50,
            sorter: (a, b) => a.bid - b.bid,
        },
        {
            title: "Ask",
            dataIndex: "ask",
            key: "ask",
            width: 50,
            sorter: (a, b) => a.ask - b.ask,
        },

        {
            title: "Volume",
            dataIndex: "volume",
            key: "volume",
            width: 100,
            sorter: (a, b) => a.volume - b.volume,
        },

        {
            title: "Contract Name",
            dataIndex: "contractName",
            key: "contractName",
            width: 165,
            sorter: (a, b) => a.contractName.localeCompare(b.contractName),
        },

        {
            title: "Option Type",
            dataIndex: "optionType",
            key: "optionType",
            width: 135,
            sorter: (a, b) => a.optionType.localeCompare(b.optionType),
        },

        {
            title: "Change",
            dataIndex: "change",
            key: "change",
            width: 90,
            sorter: (a, b) => parseFloat(a.change) - parseFloat(b.change),
        },
        {
            title: "% Change",
            dataIndex: "percChange",
            key: "percChange",
            width: 120,
            sorter: (a, b) =>
                parseFloat(a.percentChange) - parseFloat(b.percentChange),
        },

        {
            title: "Open Interest",
            dataIndex: "openInterest",
            key: "openInterest",
            width: 150,
            sorter: (a, b) => a.openInterest - b.openInterest,
        },
        {
            title: "Implied Volatility",
            dataIndex: "impliedVolatility",
            key: "impliedVolatility",
            width: 165,
            sorter: (a, b) =>
                parseFloat(a.impliedVolatility) - parseFloat(b.impliedVolatility),
        },
        {
            title: "Last Trade Date (EDT)",
            dataIndex: "lastTrade",
            key: "lastTrade",
            width: 200,
            sorter: (a, b) => new Date(a.lastTradeDate) - new Date(b.lastTradeDate),
        },
        {
            title: "Limit Price",
            dataIndex: "limitPrice",
            key: "limitPrice",
            width: 100,
            render: (_, record) => record.limit_price_1 || record.limit_price_2,
            sorter: (a, b) => (a.limit_price_1 || a.limit_price_2) - (b.limit_price_1 || b.limit_price_2),
        },



    ];


    return (
        <div className="table-container">
            <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                scroll={{x: "max-content", y: 350}}
                bordered
                locale={{
                    emptyText: <Empty
                        description={
                            <Typography.Text style={{color: 'white'}}>
                                No Data available for chosen expiry date
                            </Typography.Text>
                        }> </Empty>,
                }}
            />
        </div>
    );
};

export default TableComponent;