import React, {useState} from "react";
import WizardComponent from './DecisionTree';
import NavMenu from "../../Components/NavMenu";
import './style.css';

const TrainerPage = ({userData, setUserData, setUserToken}) => {
    const [showIntroduction, setShowIntroduction] = useState(true);

    const handleWizardStart = () => {
        setShowIntroduction(false);
    };

    return (
        <div className="trainer-page-wrapper">
            <div className="trainer-page-wrapper">
                <NavMenu/>
            </div>
            {showIntroduction && (
                <div className="introduction">
                    <h1>Welcome to the Options Trainer</h1>

                    <p>Options are financial derivatives that give traders the right, but not the obligation, to buy or
                        sell an underlying asset, like a stock, at a predetermined price before a specific expiration
                        date.
                        <br/>
                        There are two main types of options: call options, which give you the right to buy the stock,
                        and put options, which give you the right to sell it.
                        Each options contract typically represents 100 shares of the underlying stock, meaning
                        that when you buy or sell an option, you're controlling 100 shares with that single contract.
                        <br/>

                        Traders use options for various purposes, such as speculating on price movements, hedging
                        against potential losses, or generating income through strategies like covered calls. It's
                        important to note that options can be complex, with factors like strike prices (the price at
                        which you can buy or sell the stock) and expiration dates (the date by which the option must be
                        exercised) adding layers of nuance.
                        <br/>
                        Additionally, options can be influenced by time decay (the
                        loss of value as the expiration date approaches) and implied volatility (expectations of future
                        price fluctuations). Because options can amplify both gains and losses, they require careful
                        consideration and a solid understanding, especially for beginners.
                    </p>
                    <p>This tool will guide you through various scenarios to help you understand and practice options
                        trading strategies. Follow the prompts and make decisions based on the given information.
                    </p>
                    <br/>
                </div>
            )}
            <div className="trainer-page">
                <div className="content-body">
                    <WizardComponent onStart={handleWizardStart}/>
                </div>
            </div>
        </div>
    );
};

export default TrainerPage;