import React, { useEffect, useState } from "react";
import { Button, Dropdown, Menu } from "antd";
import {
  HomeOutlined,
  AppstoreOutlined,
  LogoutOutlined,
  MenuOutlined,
  TeamOutlined,
  UsergroupAddOutlined
} from "@ant-design/icons";
import "./style.css";
import logo from "../../Assets/Logo/logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { handleLogout } from "../../Utils/UpdateUsersState";

const Navbar = ({ userData, setUserData, setUserToken }) => {
  const [selectedId, setSelectedId] = useState("home");

  const navigate = useNavigate();
  const location = useLocation();

  console.log(selectedId, location.pathname);

  const handleLogin = () => {
    if (userData?.username) {
      handleLogout(setUserData, setUserToken);
    }
    navigate("/login");
  };

  useEffect(() => {
    const getSelectedKey = () => {
      if (location.pathname.includes("services")) {
        setSelectedId("services");
      } else if (location.pathname.includes("about-us")) {
        setSelectedId("aboutus");
      } else if (location.pathname.includes("our-staff")) {
        setSelectedId("ourstaff");
      } else setSelectedId("home");
    };

    getSelectedKey();
  }, [location.pathname]);

  const menu = (
    <Menu theme="dark" className="menu-dropdown">
      <Menu.Item
        key="home"
        icon={<HomeOutlined />}
        onClick={() => navigate("/home")}
      >
        Home
      </Menu.Item>

      <Menu.Item
        key="services"
        icon={<AppstoreOutlined />}
        onClick={() => navigate("/services")}
      >
        Lab
      </Menu.Item>

      <Menu.Item
        key="aboutus"
        icon={<TeamOutlined />}
        onClick={() => navigate("/about-us")}
      >
        About Us
      </Menu.Item>

      <Menu.Item
        key="ourstaff"
        icon={<UsergroupAddOutlined />}
        onClick={() => navigate("/our-staff")}
      >
        Our Staff
      </Menu.Item>

      <Menu.Item key="login" onClick={handleLogin} icon={<LogoutOutlined />}>
        {userData?.username ? "Logout" : "Login"}
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="navbar">
      <div className="logo">
        <img src={logo} alt="Logo" />
      </div>
      <div className="menu-container">
        <Menu
          mode="horizontal"
          theme="dark"
          className="menu"
          selectedKeys={selectedId}
        >
          <Menu.Item key="home" icon={<HomeOutlined />}>
            <Link to={"/home"}>Home</Link>
          </Menu.Item>
          <Menu.Item key="services" icon={<AppstoreOutlined />}>
            <Link to={"/services"}>Lab</Link>
          </Menu.Item>
          <Menu.Item key="aboutus" icon={<TeamOutlined />}>
            <Link to={"/about-us"}>About Us</Link>
          </Menu.Item>
          <Menu.Item key="ourstaff" icon={<UsergroupAddOutlined />}>
            <Link to={"/our-staff"}>Our Staff</Link>
          </Menu.Item>
        </Menu>

        <div className="btn-logout">
          <Button
            onClick={handleLogin}
            type="primary"
            icon={<LogoutOutlined />}
          >
            {userData?.username ? "Logout" : "Login"}
          </Button>
        </div>
      </div>
      <div className="dropdown-container">
        <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
          <Button
            style={{
              backgroundColor: "transparent",
              border: "none",
              color: "green",
            }}
            icon={<MenuOutlined style={{ fontSize: "20px" }} />}
          />
        </Dropdown>
      </div>
    </div>
  );
};

export default Navbar;