import { makeApiRequest } from '../makeApiRequest';

export const getPortfolioAnalytics = () =>
  makeApiRequest('GET', `/api/portfolio/analytics/`);

export const getDetailedAnalytics = () =>
  makeApiRequest('GET', `/api/portfolio/detailed-analytics/`);

export const getTearSheet = () =>
  makeApiRequest('GET', `/api/portfolio/tear-sheet/`);

export const getRoundTripAnalysis = () =>
  makeApiRequest('GET', `/api/portfolio/round-trip/`);

export const getSectorExposure = () =>
  makeApiRequest('GET', `/api/portfolio/sector-exposure/`);

export const getStrategyCapacity = () =>
  makeApiRequest('GET', `/api/portfolio/capacity/`);