import React, {useState, useEffect, useCallback} from 'react';
import {
    getPortfolioAnalytics,
    getDetailedAnalytics,
    getSectorExposure,
    getStrategyCapacity
} from '../../../API/portfolio/analyticsAPI';

// Import the existing chart components
import BarChart from '../../../Components/Charts/BarChart/BarChart';
import CumulativeReturnsChart from '../../../Components/Charts/CumulativeReturnsChart/CumulativeReturnsChart';
import DoubleLineChart from '../../../Components/Charts/DoubleLineChart/DoubleLineChart';
import PieChart from '../../../Components/Charts/PieChart/PieChart';
import SharpeRatioDisplay from '../../../Components/Charts/SharpeRatioDisplay/SharpeRatioDisplay';
import VolatilityGauge from '../../../Components/Charts/VolatilityGauge/VolatilityGauge';
import {Spin, message} from 'antd';

const PortfolioOverview = ({userData, userToken, trades}) => {
    const userId = userData?.id;
    console.log('UserId in PortfolioOverview:', userId);

    const [basicAnalytics, setBasicAnalytics] = useState(null);
    const [detailedAnalytics, setDetailedAnalytics] = useState(null);
    const [tearSheet, setTearSheet] = useState(null);
    const [roundTripAnalysis, setRoundTripAnalysis] = useState(null);
    const [sectorExposure, setSectorExposure] = useState(null);
    const [strategyCapacity, setStrategyCapacity] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchAnalytics = useCallback(async () => {
    if (!userId) {
        console.error('UserId is not defined');
        return;
    }
    setLoading(true);
    try {
        const [
            basicAnalyticsData,
            detailedAnalyticsData,
            // tearSheetData,  // This should be commented out
            // roundTripData,
            sectorExposureData,
            strategyCapacityData
        ] = await Promise.all([
            getPortfolioAnalytics(userId, userToken),
            getDetailedAnalytics(userId, userToken),
            // getTearSheet(userId, userToken),  // This should be commented out
            // getRoundTripAnalysis(userId, userToken),
            getSectorExposure(userId, userToken),
            getStrategyCapacity(userId, userToken)
        ]);

        // Transform sectorExposureData to match PieChart expected format
        const transformedSectorExposure = sectorExposureData.map(item => ({
            name: item.sector,
            value: item.exposure
        }));

        setBasicAnalytics(basicAnalyticsData);
        setDetailedAnalytics(detailedAnalyticsData);
        // setTearSheet(tearSheetData);  // This should be commented out
        // setRoundTripAnalysis(roundTripData);
        setSectorExposure(transformedSectorExposure); // Set the transformed data
        setStrategyCapacity(strategyCapacityData);

        console.log('Detailed Analytics Data:', detailedAnalyticsData);
    } catch (err) {
        console.error('Error fetching analytics:', err);
        setError(err);
        message.error('Failed to load analytics data');
    } finally {
        setLoading(false);
    }
}, [userId, userToken]);

useEffect(() => {
    fetchAnalytics();
}, [fetchAnalytics]);

if (loading) return <Spin size="large" />;
if (error) return <div>Error: {error.message}</div>;


    return (
        <div>
            {basicAnalytics && (
                <div>
                    <h2>Basic Analytics</h2>
                    <BarChart data={basicAnalytics.barChartData}/>
                    {/*<PieChart data={basicAnalytics.pieChartData} />*/}
                </div>
            )}
            {detailedAnalytics && detailedAnalytics.cumulativeReturnsData && (
                <div>
                    <h2>Detailed Analytics</h2>
                    <CumulativeReturnsChart data={detailedAnalytics.cumulativeReturnsData}/>
                    <VolatilityGauge value={detailedAnalytics.volatility}/>
                    <SharpeRatioDisplay value={detailedAnalytics.sharpeRatio}/>
                </div>
            )}
            {/* Tear Sheet section commented out */}
            {/* {tearSheet && (
        <div>
          <h2>Tear Sheet</h2>
          <BoxPlotChart data={tearSheet.boxPlotData} />
          <ScatterChart data={tearSheet.scatterData} />
        </div>
      )} */}
            {/* Round Trip Analysis section commented out */}
            {/* {roundTripAnalysis && (
        <div>
          <h2>Round Trip Analysis</h2>
          <LineBarChart data={roundTripAnalysis.lineBarData} />
        </div>
      )} */}
            {sectorExposure && (
                <div>
                    <h2>Sector Exposure</h2>
                    <PieChart data={sectorExposure}/>
                </div>
            )}
            {strategyCapacity && (
                <div>
                    <h2>Strategy Capacity</h2>
                    <DoubleLineChart data={strategyCapacity.doubleLineData}/>
                </div>
            )}
        </div>
    );
};

export default PortfolioOverview;