import React from "react";
import "./style.css";
import Footer from "../../Components/Footer";
import Navbar from "../../Components/Navbar";
import {Row, Col, Card, Space} from 'antd';
import bernie from '../../Assets/Images/bernie.png';
import charlie from '../../Assets/Images/charlie.png';
import clay from '../../Assets/Images/clay.png';
import daniel from '../../Assets/Images/daniel.png';
import docHoliday from '../../Assets/Images/doc_holiday.png';
import elizabeth from '../../Assets/Images/elizabeth.png';
import emily from '../../Assets/Images/emily.png';
import lily from '../../Assets/Images/lily.png';
import monty from '../../Assets/Images/monty.png';
import sarah from '../../Assets/Images/sarah.png';

const OurStaff = ({userData, setUserData, setUserToken}) => {
    return (
        <div className="page-container">
            <Navbar userData={userData} setUserData={setUserData} setUserToken={setUserToken}/>
            <div className="our-staff-container">
                <Card className="intro-card">
                <h1 className="page-title">Meet Our Staff</h1>
                <p className="intro-paragraph">
                    Over the years, I've had thousands of employees and hundreds of contractors working for me. It was stressful. With Anothen Analytics, here are my staff of AI Agents. We created them for specific outcome benchmarking. These personas have domain related capabilities and we tasks in various rounds as single agents and orchestrated teams each working towards specific outcomes. The
                    potential gains in domain knowledge and expertise we observed is impressive.<br/>
                    It was a fun project phase.
                </p>
                </Card>


                <Space direction="vertical" size="large" style={{width: '100%'}}>
                    <Card className="staff-card">
                        <Row gutter={16} className="staff-member">
                            <Col xs={24} md={12} className="col-right">
                                <img src={emily} alt="Emily" className="staff-image"/>
                            </Col>
                            <Col xs={24} md={12} className="col-left">
                                <ul>
                                    <li>Emily was my first assistant I created. She's an Economic Analyst,
                                        specializing in macroeconomic indicators and making informed forecasts to guide
                                        investment decisions. Emily was tasked with developing a market neutral strategy
                                        focused on The Fed Chairman's press on rates. She focused on specific ETFs to
                                        target in response to the sentiment of the event. Her advice was both actionable
                                        and correct.
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Card>

                    <Card className="staff-card">
                        <Row gutter={16} className="staff-member">
                            <Col xs={24} md={12} className="col-left">
                                <img src={elizabeth} alt="Elizabeth" className="staff-image"/>
                            </Col>
                            <Col xs={24} md={12} className="col-right">
                                <ul>
                                    <li>Liz is an Economic Analyst with expertise in multidimensional perspectives and
                                        analytical thinking. She offers insightful analysis and decision-making in
                                        economics coupled with clear insights and concise explanations. Liz provided
                                        some exceptional work on event-specific analysis.
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Card>

                    <Card className="staff-card">
                        <Row gutter={16} className="staff-member">
                            <Col xs={24} md={12} className="col-right">
                                <img src={daniel} alt="Daniel" className="staff-image"/>
                            </Col>
                            <Col xs={24} md={12} className="col-left">
                                <ul>
                                    <li>Daniel is a Postgres and all things databases maestro. He and a small team of
                                        assistants put on a most impressive display of capabilities when tasked with
                                        extracting and analyzing stored data in response to a request for analysis of
                                        recent market trends and price movements.
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Card>

                    <Card className="staff-card">
                        <Row gutter={16} className="staff-member">
                            <Col xs={24} md={12} className="col-left">
                                <img src={bernie} alt="Bernie" className="staff-image"/>
                            </Col>
                            <Col xs={24} md={12} className="col-right">
                                <ul>
                                    <li>Bernie brings decades of trading experience with expertise in all forms of
                                        trading. He excels in interpreting market trends and evaluating financial
                                        statements to provide strategic investment recommendations.
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Card>

                    <Card className="staff-card">
                        <Row gutter={16} className="staff-member">
                            <Col xs={24} md={12} className="col-right">
                                <img src={charlie} alt="Charlie" className="staff-image"/>
                            </Col>
                            <Col xs={24} md={12} className="col-left">
                                <ul>
                                    <li>Charlie is a seasoned Pattern Hunter. Give him a chart and he will stun you with
                                        his analysis and actionable advice. His deep knowledge of trading concepts,
                                        patterns, and indicators makes him a perfect assistant for a quick explanation.
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Card>

                    <Card className="staff-card">
                        <Row gutter={16} className="staff-member">
                            <Col xs={24} md={12} className="col-left">
                                <img src={clay} alt="Clay" className="staff-image"/>
                            </Col>
                            <Col xs={24} md={12} className="col-right">
                                <ul>
                                    <li>Clay is our Researcher with a blend of skills in researching specific events or
                                        topics, market analysis, and communication. He focuses on providing relevant and
                                        timely research data. Whether searching for specific events, sentiment analysis,
                                        or summarizing news, Clay's well-rounded skill set makes him a valued asset for
                                        us.
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Card>

                    <Card className="staff-card">
                        <Row gutter={16} className="staff-member">
                            <Col xs={24} md={12} className="col-right">
                                <img src={docHoliday} alt="Doc Holliday" className="staff-image"/>
                            </Col>
                            <Col xs={24} md={12} className="col-left">
                                <ul>
                                    <li>Doc Holiday is our Technical Document Writer. His ability to simplify complex
                                        technical concepts into digestible content has made him invaluable. He has
                                        crafted all of our technical documentation. He also worked on converting some of
                                        the docs into scripts for some AI-generated videos we made.
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Card>
                </Space>
            </div>
            <Footer/>
        </div>
    );
};

export default OurStaff;