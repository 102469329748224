// optionanalyzer/layout/index

import React, {useEffect, useRef, useState} from "react";
import {Input, Layout, Select, notification, Button, Divider} from "antd";
import ReactTooltip from "react-tooltip";


import "./style.css";
import {
    getBoxData,
    getExpiry,
    postPremium,
    startAnalyzer,
} from "../../../API/analyzer";


const {Content} = Layout;
const LayoutComponent = ({
                             children,
                             setSymbol,
                             symbol,
                             broker,
                             setBroker,
                             strategy,
                             setStrategy,
                             loading,
                             setLoading,
                             closeData,
                             setCloseData,
                             expiry,
                             setExpiry,
                             ownership,
                             setOwnership,
                             duration,
                             setDuration,
                             earningDate,
                             setEarningDate,
                             premium,
                             setPremium,
                             quantity1,
                             setQuantity1,
                             quantity2,
                             setQuantity2,
                             setPremiumData,
                             premiumData,
                             filteredPremiumData,
                             setFilteredPremiumData,
                             strike1,
                             setStrike1,
                             strike2,
                             setStrike2,
                             min,
                             setMin,
                             max,
                             setMax,
                             result,
                             setResult,
                         }) => {
    const [inputSymbol, setInputSymbol] = useState(''); // New state for input symbol

    const [filteredExpiryDates, setFilteredExpiryDates] = useState([]);
    const [expiryDates, setExpiryDates] = useState(undefined);
    const [positionVerified, setPositionVerified] = useState(false);
    const timeoutPremiumRef = useRef(null);
    const timeoutQuantity1Ref = useRef(null);
    const timeoutQuantity2Ref = useRef(null);
    const timeoutMinRef = useRef(null);

    const [contractName, setContractName] = useState('');
    const [lastPrice, setLastPrice] = useState('');

    const [inputStrategy, setInputStrategy] = useState(''); // State for input strategy

    const getLegTitle = (type) => `${type} Leg`;

    const handleSymbolInputChange = (e) => {
        const value = e.target.value.toUpperCase();
        setInputSymbol(value); // Update Symbol state
        if (!value) {
            setCloseData({
                num_shares: "",
                open: true,
                order_id: "",
                type: "",
                amount: "",
                price: "",
                status: "",
                dayb4_close_price: 0,
                last_close_price: 0,
            });
        }
    };

    const handleSymbolChange = () => {
        if (!inputSymbol) {
            return notification.error({
                message: 'Input Error',
                description: 'Please enter a symbol and select a duration.',
            });
        }
        setSymbol(inputSymbol)
    }


    const getPremium = async (
        min,
        max,
        sys,
        premium,
        exp,
        strategy,
        strike_1,
        quantity_1,
        strike_2,
        quantity_2
    ) => {
        setLoading(true);
        if (sys) {
            try {
                const res = await postPremium({
                    symbol: sys,
                    premium_percentage_threshold: premium,
                    expiry: exp,
                    strategy: strategy,
                    strike_1,
                    quantity_1,
                    strike_2,
                    quantity_2,
                    min,
                    max,
                });
                if (res?.status === 200) {
                    const obj = res?.data;
                    setPremiumData(obj);  // Set all data without filtering

                } else {
                    setPremiumData({
                        selected_calls: [],
                        selected_puts: [],
                        available_strike_1: [],
                        last_price_1: 0,
                        type_1: "",
                        premium_percentage_1: 0,
                        premium_percentage_threshold_1: 0,
                        contract_name_1: "",
                        available_strike_2: [],
                        last_price_2: 0,
                        type_2: "",
                        premium_percentage_2: 0,
                        premium_percentage_threshold_2: 0,
                        contract_name_2: "",
                    });
                }
            } catch (error) {
                console.error("Error fetching box data:", error);
                setPremiumData({
                    selected_calls: [],
                    selected_puts: [],
                    available_strike_1: [],
                    last_price_1: 0,
                    type_1: "",
                    premium_percentage_1: 0,
                    premium_percentage_threshold_1: 0,
                    contract_name_1: "",
                    available_strike_2: [],
                    last_price_2: 0,
                    type_2: "",
                    premium_percentage_2: 0,
                    premium_percentage_threshold_2: 0,
                    contract_name_2: "",
                });
                // Clear session storage if there's an error
                sessionStorage.removeItem('optionsChainData');
            }
        }
        setLoading(false);
    };

    const getLatestBox = async (sys, exp) => {
        setLoading(true);
        if (sys) {
            try {
                const res = await getBoxData(sys, exp);
                if (res?.status === 200) {
                    setEarningDate({
                        next_earning_date: new Date(res?.data?.next_earning_date),
                        selected_date_pd: new Date(res?.data?.selected_date_pd),
                    });
                } else {
                    setEarningDate({
                        next_earning_date: "",
                        selected_date_pd: "",
                    });
                }
            } catch (error) {
                console.error("Error fetching box data:", error);
                setEarningDate({
                    next_earning_date: "",
                    selected_date_pd: "",
                });
            }

        }
        setLoading(false);
    };
    const handleFetchExpirationDates = async () => {
        if (symbol) {
            setLoading(true);
            try {
                const res = await getExpiry(symbol);
                console.log("Fetching expiry dates for symbol:", symbol);
                console.log("API response:", res?.data);
                if (res?.status === 200 && Array.isArray(res?.data?.valid_exps)) {
                    setPremiumData(res?.data?.premium);
                    setExpiryDates(res?.data?.valid_exps);
                    filterExpiryDates(res?.data?.valid_exps, duration);
                    applyPremiumDataFilter(res?.data?.premium, res?.data?.valid_exps[0]?.value, strategy);

                    const mindata = await startAnalyzer({symbol: symbol, broker: broker});
                    if (mindata?.status === 200) {
                        setCloseData(mindata?.data);
                        setMin(0.5 * mindata?.data?.dayb4_close_price);
                        setMax(1.5 * mindata?.data?.dayb4_close_price);
                    } else {
                        notification.error({
                            message: "Request Failed",
                            description: mindata?.data?.detail,
                        });
                    }

                } else {
                    console.log("Invalid response or no expiry dates");
                    setFilteredExpiryDates([]);
                    setPremiumData({})
                    setExpiry(null);
                }
            } catch (error) {
                console.error("Error fetching expiry data:", error);
                setFilteredExpiryDates([]);
                setPremiumData({})
                setExpiry(null);
            }
            setLoading(false);
        }
    }
    useEffect(() => {
        handleFetchExpirationDates();
    }, [symbol]);

    const applyPremiumDataFilter = (premiumData, expiry, strategy) => {
        if (premiumData?.selected_calls?.length && premiumData?.selected_puts?.length) {
            let filteredData = JSON.parse(JSON.stringify(premiumData));
            if (strategy !== 'Collar') {
                filteredData.selected_calls = premiumData.selected_calls.filter(call => call.expirationDate === expiry && call.premiumPerc >= premium);
                filteredData.selected_puts = premiumData.selected_puts.filter(put => put.expirationDate === expiry && put.premiumPerc >= premium);
            } else {
                filteredData.selected_calls = premiumData.selected_calls.filter(call => call.expirationDate === expiry);
                filteredData.selected_puts = premiumData.selected_puts.filter(put => put.expirationDate === expiry);
            }
            const callStrikes = filteredData.selected_calls.map(option => option.strike);
            const putStrikes = filteredData.selected_puts.map(option => option.strike);

            const uniqueCallStrikes = [...new Set(callStrikes)].sort((a, b) => a - b).map(strike => ({
                label: strike.toFixed(2),
                value: strike.toFixed(2)
            }));

            const uniquePutStrikes = [...new Set(putStrikes)].sort((a, b) => a - b).map(strike => ({
                label: strike.toFixed(2),
                value: strike.toFixed(2)
            }));

            filteredData.available_strike_1 = uniquePutStrikes
            filteredData.available_strike_2 = uniqueCallStrikes
            if (!strike1 || !uniquePutStrikes.includes(strike1)) setStrike1(parseFloat(uniquePutStrikes[0]?.value).toFixed(2));
            if (!strike2 || !uniqueCallStrikes.includes(strike2)) setStrike2(parseFloat(uniqueCallStrikes[0]?.value).toFixed(2));
            if (!filteredData.selected_calls.length && !filteredData.selected_puts.length) {
                console.log(`No options with at least ${premium}% premium found`)
            } else {
                setFilteredPremiumData(filteredData);
            }
        }
    }

    const handleSymbolExecute = async (mys, myb) => {
        setOwnership(true);
        // if (!mys || !myb) {
        //   mys = symbol;
        //   myb = broker;
        // }
        // if (mys) {
        //   setLoading(true);
        //   const res = await startAnalyzer({symbol: mys, broker: myb});
        //   if (res?.status === 200) {
        //     setCloseData(res?.data);
        //     setMin(0.5 * res?.data?.dayb4_close_price);
        //     setMax(1.5 * res?.data?.dayb4_close_price);
        //   } else {
        //     notification.error({
        //       message: "Request Failed",
        //       description: res?.data?.detail,
        //     });
        //   }
        //   setLoading(false);
        // }
    };

    const handleMin = (e) => {
        setMin(e.target.value);
    };


    const handleMax = (e) => {
        setMax(e.target.value);
    };

    const handleBrokerChange = async (value) => {
        setBroker(value);
        await handleSymbolExecute(symbol, value);
    };


    // Add this function to filter expiry dates based on the selected duration
    const filterExpiryDates = (expiryDates, duration) => {
        const today = new Date();
        const daysFromNow = (days) => new Date(today.getTime() + days * 24 * 60 * 60 * 1000);
        console.log(expiryDates)
        if (!expiryDates || !duration) return;
        const filteredExpiryDates = expiryDates?.filter(exp => {
            const expiryDate = new Date(exp.value);
            switch (duration) {
                case "Less than 30 days":
                    return expiryDate >= today && expiryDate < daysFromNow(30);
                case "30-90 Days":
                    return expiryDate >= daysFromNow(30) && expiryDate < daysFromNow(90);
                case "90+ days":
                    return expiryDate >= daysFromNow(90);
                default:
                    return true; // Include all dates if duration is not recognized
            }
        });
        console.log(filteredExpiryDates)
        console.log(filteredExpiryDates[0]?.value)
        setFilteredExpiryDates(filteredExpiryDates);
        setExpiry(filteredExpiryDates[0]?.value);
    };


    // Modify handleExpiryChange to use session storage
    const handleExpiryChange = async (value) => {
        setExpiry(value);
        applyPremiumDataFilter(premiumData, value, strategy);

        try {
            await getLatestBox(symbol, value);
        } catch (error) {
            console.error("Error fetching expiry data:", error);
        }
    };

    const handleDurationChange = async (value) => {
        setDuration(value);
        await filterExpiryDates(expiryDates, value);
        // await getLatestExpiry(symbol, value);
    };


    const handleStrategyChange = async (value) => {
        setStrategy(value);
        setPositionVerified(true);
        applyPremiumDataFilter(premiumData, expiry, value);
    };


    const handlePremiumChange = (e) => {
        setPremium(e.target.value);
        if (timeoutPremiumRef.current) {
            clearTimeout(timeoutPremiumRef.current);
        }

        timeoutPremiumRef.current = setTimeout(async () => {
            await getPremium(
                min,
                max,
                symbol,
                e.target.value,
                expiry,
                strategy,
                strike1,
                null,
                strike2,
                null
            );
        }, 2000);
    };

    const handleQuantity1Change = async (e) => {
        setQuantity1(e.target.value);
    };

    const handleQuantity2Change = (e) => {
        setQuantity2(e.target.value);
    };


    const handleStrike1Change = (value) => {

        // console.log("handleStrike1Change called with value:", value);
        setStrike1(value);

        const selectedPutOption = filteredPremiumData.selected_puts.find(option => parseFloat(option.strike) === parseFloat(value));
        // console.log("selectedPutOption:", selectedPutOption);

        setFilteredPremiumData(prevData => {
            // console.log("Previous filteredPremiumData:", prevData);
            // console.log("All selected puts:", premiumData.selected_puts);
            // const relevantPuts = premiumData.selected_puts.filter(option => option.expirationDate === expiry);
            // console.log("Puts for selected expiration date:", relevantPuts);

            // Add these logs to verify filtering and selection
            // console.log("Filtered Puts:", relevantPuts);
            // console.log("selectedPutOption after filtering:", selectedPutOption);

            // Calculate limit price
            const limitPrice = selectedPutOption ?
                (parseFloat(selectedPutOption.ask) + parseFloat(selectedPutOption.bid)) / 2 :
                0;

            const newData = {
                ...prevData,
                contract_name_1: selectedPutOption?.contractName || '',
                last_price_1: selectedPutOption?.lastPrice || '',
                strike_1: value,
                limit_price_1: limitPrice.toFixed(2), // Round to 2 decimal places
            };

            // console.log("New contract_name_1:", newData.contract_name_1);
            // console.log("New last_price_1:", newData.last_price_1);
            // console.log("New strike_1:", newData.strike_1);
            // console.log("New limit_price_1:", newData.limit_price_1);
            //
            // console.log("New filteredPremiumData (before return):", newData);
            return newData;
        });
    };


    const handleStrike2Change = (value) => {
        // console.log("handleStrike2Change called with value:", value);
        setStrike2(value);

        const selectedCallOption = filteredPremiumData.selected_calls.find(option => parseFloat(option.strike) === parseFloat(value));
        // console.log("selectedCallOption:", selectedCallOption);

        setFilteredPremiumData(prevData => {
            // console.log("Previous filteredPremiumData:", prevData);

            // Calculate limit price
            const limitPrice = selectedCallOption ?
                (parseFloat(selectedCallOption.ask) + parseFloat(selectedCallOption.bid)) / 2 :
                0;

            const newData = {
                ...prevData,
                contract_name_2: selectedCallOption?.contractName || '',
                last_price_2: selectedCallOption?.lastPrice || '',
                strike_2: value,
                limit_price_2: limitPrice.toFixed(2), // Round to 2 decimal places
            };
            //
            // console.log("New contract_name_2:", newData.contract_name_2);
            // console.log("New last_price_2:", newData.last_price_2);
            // console.log("New strike_2:", newData.strike_2);
            // console.log("New limit_price_2:", newData.limit_price_2);
            //
            // console.log("New filteredPremiumData (before return):", newData);
            return newData;
        });
    };


    return (
        <Layout className="layout">
            <div className="flex flex-wrap items-start space-x-4 p-4"
                 style={{justifyContent: 'space-between', WebkitFlexWrap: 'wrap'}}>
                {/* Broker Selection */}
                <div className="flex-none w-48">
                    <h2>Start Here</h2>
                    <p>Choose a Broker: <span className="disabled-text">(disabled)</span></p>
                    <Select
                        className="select"
                        defaultValue="None"
                        onChange={handleBrokerChange}
                        options={[
                            {value: "None", label: "None"},
                            {value: "Alpaca", label: "Alpaca"},
                        ]}
                    />
                </div>

                {/* Ticker Symbol Input */}
                <div className="flex-none w-48">
                    <p className="yellow-text step-container">
                        <span className="step-indicator">1</span>
                        <span className="step-text">Enter Ticker Symbol</span>
                    </p>
                    <Input
                        type="text"
                        className="symbol-input"
                        onChange={handleSymbolInputChange}
                        value={inputSymbol}
                        placeholder="Enter symbol"
                    />
                    <Button
                        onClick={handleSymbolChange}
                        type="primary"
                        className="custom-fetch-options-button mx-auto"
                        style={{margin: 'auto', display: 'block', marginTop: '10px'}}
                    >
                        Submit
                    </Button>
                    {symbol && (
                        <Button
                            onClick={handleFetchExpirationDates}
                            type="primary"
                            className="custom-fetch-options-button mx-auto"
                            style={{margin: 'auto', display: 'block', marginTop: '10px'}}
                        >
                            Reset
                        </Button>
                    )}
                </div>

                {/* Time Span Selection and Expiration Date Selection */}
                <div className="flex-none w-48">
                    <div>
                        <p className="step-container">
                            <span className="step-indicator">2</span>
                            <span className="step-text">Choose a time span</span>
                        </p>
                        <Select
                            className="select"
                            defaultValue={duration}
                            onChange={handleDurationChange}
                            options={[
                                {value: "Less than 30 days", label: "Less than 30 days"},
                                {value: "30-90 Days", label: "30-90 Days"},
                                {value: "90+ days", label: "90+ days"},
                            ]}
                        />
                    </div>

                    {filteredExpiryDates.length > 0 && (
                        <div className="mt-4">
                            <p className="step-container">
                                <span className="step-indicator">3</span>
                                <span className="step-text">Select an Expiration Date</span>
                            </p>
                            <Select
                                className="select"
                                options={filteredExpiryDates.map((exp) => ({
                                    label: exp.label.substring(0, 10),
                                    value: exp.value,
                                }))}
                                value={expiry}
                                onChange={handleExpiryChange}
                            />
                        </div>
                    )}
                </div>

                {/* Strategy Selection (Step 4) */}
                <div className="flex-none w-48">
                    <div>
                        <p className="step-container">
                            <span className="step-indicator">4</span>
                            <span className="step-text">Choose Strategy:</span>
                        </p>
                        <Select
                            className="select"
                            value={strategy}
                            onChange={handleStrategyChange}
                            options={[
                                {value: "Put Sale", label: "Put Sale"},
                                {value: "Covered Call", label: "Covered Call"},
                                {value: "Collar", label: "Collar"},
                            ]}
                        />
                    </div>

                    <div>
                        {strategy === "Put Sale" && (
                            <span className="premium-description">
              The Strike Prices show premiums 1% or greater of Strike Price.
              <br/>
              Select your target by scrolling the table to find your strike
              price and then select it below.
            </span>
                        )}

                        {strategy === "Covered Call" && !ownership && (
                            <span className="premium-description">
              The Strike Prices show premiums 1% or greater of Strike Price.
              <br/>
              Select your target by scrolling the table to find your strike
              price and then select it below.
            </span>
                        )}

                        {strategy === "Collar" && !ownership && (
                            <span className="premium-description">
              Collars do not have a Premium % filter.
              <br/>
              Scroll the tables to select your desired options.
            </span>
                        )}
                    </div>
                </div>

                {/* Strike Price Selection (Step 5) */}
                <div className="flex-none w-48">
                    {strategy && (
                        <div>
                            <p className="premium-step-container">
                                <span className="premium-step-indicator">5</span>
                                <span className="premium-step-text">Select a Strike Price </span>
                            </p>

                            {/* for put sale */}
                            {strategy === "Put Sale" && (
                                <>
                                    <h3 className="leg-title"> {getLegTitle(premiumData?.type_1)} </h3>
                                    <Select
                                        className="select"
                                        options={filteredPremiumData?.available_strike_1 || []}
                                        value={strike1}
                                        onChange={handleStrike1Change}
                                    />
                                </>
                            )}


                            {/*for covered call*/}
                            {strategy === "Covered Call" && (
                                <>
                                    {!ownership ? (
                                        <div className="verify-button-container">
                                            <p>Please verify your position before proceeding:</p>
                                            <Button onClick={() => handleSymbolExecute(symbol, broker)} type="primary">
                                                Verify Position
                                            </Button>
                                        </div>
                                    ) : (
                                        <>
                                            <h3 className="leg-title">
                                                {getLegTitle(premiumData?.type_2)}
                                            </h3>
                                            <Select
                                                className="select"
                                                options={filteredPremiumData?.available_strike_2 || []}
                                                value={strike2}
                                                onChange={handleStrike2Change}
                                            />
                                        </>
                                    )}
                                </>
                            )}
                            {strategy === "Collar" && (
                                <>
                                    {!ownership ? (
                                        <div className="verify-button-container">
                                            <p>Please verify your position before proceeding:</p>
                                            <Button onClick={() => handleSymbolExecute(symbol, broker)} type="primary">
                                                Verify Position
                                            </Button>
                                        </div>
                                    ) : (
                                        <>
                                            <h3 className="leg-title">
                                                {getLegTitle(premiumData?.type_2)}
                                            </h3>
                                            <Select
                                                className="select"
                                                options={filteredPremiumData?.available_strike_2 || []}
                                                value={strike2}
                                                onChange={handleStrike2Change}
                                            />
                                            <h3 className="leg-title">
                                                {getLegTitle(premiumData?.type_1)}
                                            </h3>
                                            <Select
                                                className="select"
                                                options={premiumData?.available_strike_1 || []}
                                                value={strike1}
                                                onChange={handleStrike1Change}
                                            />
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    )}
                </div>

            </div>
            <Content className="content">{children}</Content>
        </Layout>
    );
};

export default LayoutComponent;