import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import Home from "../Pages/Home";
import Login from "../Pages/Login";
import Services from "../Pages/Services";
import AboutUs from "../Pages/AboutUs";
import OurStaff from "../Pages/OurStaff";
import ContactForm from "../Pages/ContactForm";
import Registration from "../Pages/Registration"; // Add this import


const PublicRoutes = ({setUserData, setUserToken}) => {
    return (
        <Routes>
            <Route path="/home" element={<Home/>}/>
            <Route path="/" element={<Home/>}/>
            <Route
                path="/login"
                element={
                    <Login setUserData={setUserData} setUserToken={setUserToken}/>
                }
            />
            {/* Add the new Registration route */}
            <Route
                path="/register"
                element={ <Registration setUserData={setUserData} setUserToken={setUserToken}/>
                }
            />
            <Route path="/services" element={<Services/>}/>
            <Route path="/about-us" element={<AboutUs/>}/>
            <Route path="/our-staff" element={<OurStaff/>}/>
            <Route path="/contact" element={<ContactForm/>}/>
            <Route path="*" element={<Navigate to="/login" replace/>}/>
        </Routes>
    );
};

export default PublicRoutes;