// frontend/src/Pages/OptionsTrainer/HelpPage/index.js

import React from "react";
import "../OptionsTrainer/style.css";
import { SlOptionsVertical } from "react-icons/sl";
import { Button, Dropdown, Menu } from "antd";
import { HomeOutlined, LogoutOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
// import WizardComponent from "./DecisionTree";


const HelpPage = () => {
  const navigate = useNavigate();

  return (
    <div className="help-page">

      <div className="back-home">
        <div
          className="back-home-text"
          onClick={() => {
            navigate("/option-trainer");
          }}
        >
          <IoMdArrowBack />
          <div className="back-home-t">Back to Option Trainer</div>
        </div>
      </div>
      <div className="content-body">

      </div>
    </div>
  );
};

export default HelpPage;