import React from "react";
import Home from "../Pages/Home";
import {Navigate, Route, Routes} from "react-router-dom";
import Services from "../Pages/Services";
import OptionAnalyzer from "../Pages/OptionAnalyzer";
import OptionsTrainer from "../Pages/OptionsTrainer";
import HelpPage from "../Pages/HelpPage";
import AboutUs from "../Pages/AboutUs";
import OurStaff from "../Pages/OurStaff";
import ContactForm from "../Pages/ContactForm";
import TradeJournal from "../Pages/TradeJournal";


const PrivateRoutes = ({userData, userToken, setUserData, setUserToken}) => {
    return (
        <Routes>
            <Route
                path="/home"
                element={
                    <Home
                        userData={userData}
                        userToken={userToken}
                        setUserData={setUserData}
                        setUserToken={setUserToken}
                    />
                }
            />
            <Route
                path="/"
                element={
                    <Home
                        userData={userData}
                        userToken={userToken}
                        setUserData={setUserData}
                        setUserToken={setUserToken}
                    />
                }
            />
            <Route
                path="/about-us"
                element={
                    <AboutUs
                        userData={userData}
                        userToken={userToken}
                        setUserData={setUserData}
                        setUserToken={setUserToken}
                    />
                }
            />
            <Route
                path="/services"
                element={
                    <Services
                        userData={userData}
                        userToken={userToken}
                        setUserData={setUserData}
                        setUserToken={setUserToken}
                    />
                }
            />
            <Route
                path="/our-staff"
                element={
                    <OurStaff
                        userData={userData}
                        userToken={userToken}
                        etUserData={setUserData}
                        setUserToken={setUserToken}
                    />
                }
            />
            <Route
                path="/contact"
                element={
                    <ContactForm
                        userData={userData}
                        userToken={userToken}
                        setUserData={setUserData}
                        setUserToken={setUserToken}
                    />
                }
            />

            {userData?.pages?.includes("option_analyzer") ? (
                <Route
                    path="/option-analyzer"
                    element={
                        <OptionAnalyzer
                            userData={userData}
                            userToken={userToken}
                            setUserData={setUserData}
                            setUserToken={setUserToken}
                        />
                    }
                />
            ) : null}
            {userData?.pages?.includes("option_trainer") ? (
                <>
                    <Route
                        path="/option-trainer"
                        element={
                            <OptionsTrainer
                                userData={userData}
                                userToken={userToken}
                                setUserData={setUserData}
                                setUserToken={setUserToken}
                            />
                        }
                    />
                    <Route path="/option-trainer/help" element={<HelpPage/>}/>
                </>
            ) : null}
            {userData?.pages?.includes("trade_journal") ? (
                <Route
                    path="/trade-journal"
                    element={
                        <TradeJournal
                            userData={userData}
                            userToken={userToken}
                            setUserData={setUserData}
                            setUserToken={setUserToken}
                        />
                    }
                />
            ) : null}
            <Route path="*" element={<Navigate to="/home" replace/>}/>
        </Routes>
    );
};

export default PrivateRoutes;