import React from "react";
import "./style.css";
import Navbar from "../../Components/Navbar";
import {Button} from "antd";
import {ArrowRightOutlined} from "@ant-design/icons";
import Footer from "../../Components/Footer";
import {useNavigate} from "react-router-dom";

const Home = ({userData, setUserData, setUserToken}) => {
    const navigate = useNavigate();

    const handleServices = () => {
        navigate("/services");
    };

    return (
        <div className="home">
            <Navbar
                userData={userData}
                setUserData={setUserData}
                setUserToken={setUserToken}
            />

            <div className="container">
                <div className="welcome-container">
                    <div className="left">
                        <span style={{color: "#06d44e"}}>Anothen Analytics </span>
                        <span className="specific-text">
              It's a way of looking at things
            </span>
                    </div>
                    <div className="right">
                        <div className="right-inner">
                            <Button
                                className="button-custom"
                                type="primary"
                                icon={<ArrowRightOutlined/>}
                                onClick={handleServices}
                            >
                                Head to the Lab
                            </Button>
                            <br/>
                            <span>Come see what we're working on</span>
                        </div>
                    </div>
                </div>
                <div className="quote-container">
                    <div className="quote">
                        <p>
                            “I wasn’t the fastest guy in the world. I wouldn’t have done well in an
                            Olympiad or a math contest. But I like to ponder. And pondering things, just sort
                            of thinking about it and thinking about it, turns out to be a pretty good approach.”
                            <br/> — Jim Simons, Renaissance Technologies
                        </p>
                    </div>
                    <div className="quote-divider"></div>

                    {/*<div className="quote">*/}
                    {/*    <p>*/}
                    {/*        "If I had an hour to solve a problem, I'd spend 55 minutes thinking about the*/}
                    {/*        problem and 5 minutes thinking about solutions."*/}
                    {/*        <br/> — Attributed to Albert Einstein*/}
                    {/*    </p>*/}
                    {/*</div>*/}
                    {/*<div className="quote-divider"></div>*/}

                    {/*<div className="quote">*/}
                    {/*    <p>*/}
                    {/*        "There is nothing that can happen to you that you cannot do something about, even*/}
                    {/*        if it's only to change your attitude so it doesn't destroy your spirit."*/}
                    {/*        <br/> — Napoleon Hill*/}
                    {/*    </p>*/}
                    {/*</div>*/}
                </div>

            </div>

            {/*<hr />*/}
            {/*<div>*/}
            {/*  <br />*/}

            {/*  <br />*/}
            {/*  <ul className="custom-spacing">*/}
            {/*    <strong>About Automation:</strong>*/}
            {/*    <p>*/}
            {/*      A research project we ran in 2022 engaged a professionally managed 'trading bot'. Our goal was to create benchmarks and metrics. However, we unexpectedly discovered gaps and limitations of the bot, as we grew to understand the providers' view of automated trading. It is actually a form of hybrid trading which failed to meet our expectations on multiple levels.*/}
            {/*    </p>*/}
            {/*      The lessons learned from our experience helped shape our targeted capabilities. The key capability we are striving to create for Robbie is what we call "Market Awareness". This set of functions empowers Robbie to autonomously analyze diverse datasets and real-time metrics, allowing him to determine the relevance and suitability of different trading strategies based on his own perception of market conditions.*/}


            {/*      Robbie has access to a library of proven trading strategies, each designed to achieve success under specific market conditions. By continuously scanning and processing real-time market data, Robbie gains a deep understanding of the current market state. This understanding is further enhanced by his own trade journal, which allows him to track and evaluate the performance of the strategies he employs in real-time.*/}

            {/*      Through the synthesis of market data, the strategy library, and his trade journal, Robbie develops a keen "Market Awareness". This capability enables him to dynamically select the most appropriate trading strategy based on his assessment of the prevailing market conditions. As market conditions evolve, Robbie adaptively switches to strategies that he determines are likely to be more effective, drawing on his analysis and the historical performance data at his disposal.*/}

            {/*      By embedding "Market Awareness" into Robbie's core functionality, we aim to create an autonomous trading system that can optimize its performance and success in the face of ever-changing market landscapes. Robbie's ability to make informed, data-driven decisions without the need for manual intervention sets him apart as a cutting-edge trading solution.*/}
            {/*  </ul>*/}
            {/*</div>*/}


            <Footer/>
        </div>
    );
};

export default Home;