import React, { useState, useEffect } from 'react';
import { DatePicker, Table, Spin, message, Card, Row, Col, Button } from 'antd';
import { getTrades } from '../../../API/tradeJournal';
import TradeDetailsModal from '../../../Components/TradeDetailsModal/TradeDetailsModal';
import './style.css';

const { RangePicker } = DatePicker;

const OrderBook = ({ userToken, refreshTrigger, onRefreshComplete }) => {
  const [orderData, setOrderData] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [loading, setLoading] = useState(true);
  const [summary, setSummary] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTrade, setSelectedTrade] = useState(null);

  useEffect(() => {
    loadOrderData();
  }, [dateRange, refreshTrigger, userToken]);

  const loadOrderData = async () => {
    try {
      setLoading(true);
      const data = await getTrades(userToken, dateRange[0], dateRange[1]);
      setOrderData(data);
      calculateSummary(data);
      if (refreshTrigger) {
        onRefreshComplete();
      }
    } catch (error) {
      console.error('Error loading order data:', error);
      message.error('Failed to load order data');
    } finally {
      setLoading(false);
    }
  };

  const calculateSummary = (data) => {
    const totalTrades = data.length;
    const winningTrades = data.filter(trade => trade.trade_result === 'win').length;
    const losingTrades = data.filter(trade => trade.trade_result === 'fail').length;
    const winRate = (winningTrades / totalTrades) * 100;

    const totalProfit = data.reduce((sum, trade) => {
      const profit = (parseFloat(trade.exit_price) - parseFloat(trade.price)) * parseFloat(trade.quantity);
      return sum + (trade.trade_type === 'long' ? profit : -profit);
    }, 0);

    setSummary({
      totalTrades,
      winningTrades,
      losingTrades,
      winRate: winRate.toFixed(2),
      totalProfit: totalProfit.toFixed(2)
    });
  };

  const handleDateChange = (dates) => {
    setDateRange(dates);
  };

  const showTradeDetails = (trade) => {
    setSelectedTrade(trade);
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setSelectedTrade(null);
  };

  const handleTradeUpdate = (updatedTrade) => {
    // Implement the logic to update the trade
    console.log('Updated trade:', updatedTrade);
    setIsModalVisible(false);
    // You might want to refresh the trade data here
    loadOrderData();
  };

  const columns = [
    {
      title: 'Symbol',
      dataIndex: 'symbol',
      key: 'symbol',
    },
    {
      title: 'Asset Type',
      dataIndex: 'asset_type',
      key: 'asset_type',
    },
    {
      title: 'Trade Type',
      dataIndex: 'trade_type',
      key: 'trade_type',
    },
    {
      title: 'Entry Price',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Exit Price',
      dataIndex: 'exit_price',
      key: 'exit_price',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Entry Time',
      dataIndex: 'trade_entry_time',
      key: 'trade_entry_time',
    },
    {
      title: 'Exit Time',
      dataIndex: 'exit_date',
      key: 'exit_date',
    },
    {
      title: 'Result',
      dataIndex: 'trade_result',
      key: 'trade_result',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Button onClick={() => showTradeDetails(record)}>View Details</Button>
      ),
    },
  ];

  return (
    <div className="order-book">
      <div className="order-book-container">
        <div className="filters">
          <RangePicker onChange={handleDateChange} />
        </div>
        
        {loading ? (
          <Spin size="large" />
        ) : (
          <>
            <Row gutter={16} style={{ marginBottom: 16 }}>
              <Col span={4}>
                <Card title="Total Trades" size="small">
                  {summary.totalTrades}
                </Card>
              </Col>
              <Col span={4}>
                <Card title="Winning Trades" size="small">
                  {summary.winningTrades}
                </Card>
              </Col>
              <Col span={4}>
                <Card title="Losing Trades" size="small">
                  {summary.losingTrades}
                </Card>
              </Col>
              <Col span={4}>
                <Card title="Win Rate" size="small">
                  {summary.winRate}% 
                </Card>
              </Col>
              <Col span={4}>
                <Card title="Total Profit/Loss" size="small">
                  ${summary.totalProfit}
                </Card>
              </Col>
            </Row>
            <div className="order-book-table">
              <Table 
                dataSource={orderData} 
                columns={columns} 
                rowKey="id"
                scroll={{ x: true }}
              />
            </div>
          </>
        )}
      </div>
      <TradeDetailsModal
        visible={isModalVisible}
        onCancel={handleModalCancel}
        onSave={handleTradeUpdate}
        trade={selectedTrade}
      />
    </div>
  );
};

export default OrderBook;