import React from 'react';
import { Line } from 'react-chartjs-2';

const CumulativeReturnsChart = ({ data }) => {
  if (!data || !data.dates || !data.returns) {
    return <div>No data available</div>;
  }
  const chartData = {
    labels: data.dates,
    datasets: [
      {
        label: 'Cumulative Returns',
        data: data.returns,
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1
      }
    ]
  };

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Cumulative Returns'
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Returns'
        }
      },
      x: {
        title: {
          display: true,
          text: 'Date'
        }
      }
    }
  };

  return <Line data={chartData} options={options} />;
};

export default CumulativeReturnsChart;