import axios from 'axios';
import { getToken } from "../Utils/UpdateUsersState";

export const makeApiRequest = async (method, endpoint, data = null, params = null) => {
  try {
    const config = {
      method,
      url: `${process.env.REACT_APP_BACKEND_URL}${endpoint}`,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };

    if (data) {
      config.data = data;
      // If data is FormData, let axios set the correct content-type
      if (data instanceof FormData) {
        config.headers['Content-Type'] = 'multipart/form-data';
      }
    }

    if (params) {
      config.params = params;
    }

    console.log('Making API request with config:', config);

    const response = await axios(config);
    console.log('API response:', response.data);
    return response.data;
  } catch (e) {
    console.error(`Error in ${method} ${endpoint}:`, e);
    if (e.response) {
      console.error('Error response:', e.response.data);
    }
    throw e; // Throw the error so it can be caught in the calling function
  }
};