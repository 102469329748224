import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div
      style={{
        color: "#ffffffb2",
        fontWeight: 200,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingBottom: "30px",
        paddingTop: "40px",
        paddingRight: "10px",
        paddingLeft: "10px",
        textAlign: "center",
      }}
    >
      <Link
        to="/contact"
        style={{
          color: "#ffffffb2",
          textDecoration: "underline",
          marginBottom: "10px",
        }}
      >
        Contact Us
      </Link>
      <div>
        Copyright © 2024 Anothen Analytics. All Rights Reserved.
      </div>
    </div>
  );
};

export default Footer;