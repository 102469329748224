import React from 'react';
import { Pie } from '@ant-design/plots';

const PieChart = ({ data, valueField, nameField, height }) => {
  if (!data || data.length === 0) {
    return <div>No data available for Pie Chart</div>;
  }

  const config = {
    data,
    angleField: valueField,
    colorField: nameField,
    height: height || 300,
    // ... other configurations
  };

  return <Pie {...config} />;
};

export default PieChart;