import React, {useState, useEffect} from "react";
import "./style.css";
import LayoutComponent from "./Layout";
import {SlOptionsVertical} from "react-icons/sl";
import {Button, Collapse, Dropdown, Menu, notification, Radio} from "antd";
import {
    HomeOutlined,
    AppstoreOutlined,
    LogoutOutlined,
} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import LineChart from "./Chart";
import TableComponent from "./Table";
import Runner from "../../Assets/Gifs/runner.gif";
import {closeOrder, postPlaceOrder} from "../../API/analyzer";
import NavMenu from "../../Components/NavMenu";
import RealTimeChart from "../../Components/Charts/TradingViewChart/RealTimeChart";
import {Typography, message, Tooltip, Row, Col, Tag} from 'antd';


const {Text} = Typography;

const OptionAnalyzer = ({userData, setUserData, setUserToken}) => {
    const {Panel} = Collapse;
    const [loading, setLoading] = useState(false);
    const [broker, setBroker] = useState("None");
    const [symbol, setSymbol] = useState("");
    const [strategy, setStrategy] = useState("Put Sale");
    const [selectedPuts, setSelectedPuts] = useState([]);
    const [selectedCalls, setSelectedCalls] = useState([]);
    const [duration, setDuration] = useState("Less than 30 days");
    const [expiry, setExpiry] = useState("");
    const [ownership, setOwnership] = useState(false);
    const [closeData, setCloseData] = useState({
        num_shares: "",
        open: true,
        order_id: "",
        type: "",
        amount: "",
        price: "",
        status: "",
        dayb4_close_price: 0,
        last_close_price: 0,
    });
    const [earningDate, setEarningDate] = useState({
        next_earning_date: "",
        selected_date_pd: "",
    });
    const [premium, setPremium] = useState(1);
    const [premiumData, setPremiumData] = useState({
        selected_calls: [],
        selected_puts: [],
        available_strike_1: [],
        last_price_1: 0,
        type_1: "",
        premium_percentage_1: 0,
        premium_percentage_threshold_1: 0,
        contract_name_1: "",
        limit_price_1: 0,
        limit_price_2: 0,
        available_strike_2: [],
        last_price_2: 0,
        type_2: "",
        premium_percentage_2: 0,
        premium_percentage_threshold_2: 0,
        contract_name_2: "",
    });
    const [filteredPremiumData, setFilteredPremiumData] = useState({});
    const [quantity1, setQuantity1] = useState(1);
    const [quantity2, setQuantity2] = useState(1);
    const [strike1, setStrike1] = useState(null);
    const [strike2, setStrike2] = useState(null);



    const [highestClose, setHighestClose] = useState(null);
    const [lowestClose, setLowestClose] = useState(null);


    const [min, setMin] = useState();
    const [max, setMax] = useState();
    const [result, setResult] = useState({
        max_gain: 0,
        max_loss: 0,
        breakeven_points: 0,
        x: [],
        y: [],
    });
    const [api, contextHolder] = notification.useNotification();


    const handleCloseClick = async () => {
        setLoading(true);
        const res = await closeOrder(symbol);
        if (res?.status === 200) {
            setCloseData((c) => ({...c, open: false}));
        } else {
            api.error({
                message: "Request Failed",
                description: "Unable to cancel order. Try again later!",
            });
        }
        setLoading(false);
    };

    const handlePlaceClick = async () => {
        setLoading(true);
        if (broker !== "None") {
            const res = await postPlaceOrder(strategy, {
                strike_1: strike1,
                quantity_1: quantity1,
                strike_2: strike2,
                quantity_2: quantity2,
                premium_1: premiumData?.last_price_1,
                premium_2: premiumData?.last_price_2,
                contract_name_1: premiumData?.contract_name_1,
                contract_name_2: premiumData?.contract_name_2,
            });
            if (res?.status === 200) {
                api.success({
                    message: "Success",
                    description: "Order has been placed!",
                });
            } else {
                api.error({
                    message: "Request Failed",
                    description: "Unable to place order. Try again later!",
                });
            }
        } else {
            api.info({
                message: "Request Failed",
                description: "Select a valid broker!",
            });
        }
        setLoading(false);
    };

    const handleOwnershipClick = async () => {
        setOwnership(true);
    };

    const LegItem = ({label, value, copyable = false}) => {
        const handleCopy = () => {
            navigator.clipboard.writeText(value).then(() => {
                message.success(`Copied ${label}: ${value}`);
            });
        };

        return (
            <li className="leg-item">
                <span className="leg-label">{label}:</span>
                {copyable ? (
                    <Tooltip title="Click to copy" mouseEnterDelay={0.5}>
          <span onClick={handleCopy} className="leg-value copyable-text"
                style={{cursor: 'pointer'}}>
            {value}
          </span>
                    </Tooltip>
                ) : (
                    <span className="leg-value">{value}</span>
                )}
            </li>
        );
    };

    const LegItem2 = ({label, value}) => {
        return (
            <li className="leg-item2">
                <span className="leg-label">{label}:</span>
                <span className="leg-value">{value}</span>
            </li>
        );
    };


    const getOptionsChainData = () => {
        const data = sessionStorage.getItem('optionsChainData');
        return data ? JSON.parse(data) : null;
    };

    const getCallOptions = () => {
        const data = getOptionsChainData();
        return data ? data.selected_calls : [];
    };

    const getPutOptions = () => {
        const data = getOptionsChainData();
        return data ? data.selected_puts : [];
    };

    const [orderType, setOrderType] = useState('limit'); // Initialize orderType state
    const [timeInForce, setTimeInForce] = useState('gtc'); // Initialize timeInForce state


    const handleOrderTypeChange = (e) => {
        setOrderType(e.target.value); // Update orderType state
    };

    // Define the handler function for timeInForce change
    const handleTimeInForceChange = (e) => {
        setTimeInForce(e.target.value); // Update timeInForce state
    };

    const getSelectedOptionData = (options, strikePrice) => {
        return options.find(option => parseFloat(option.strike) === parseFloat(strikePrice));
    };


    // we are working here

    // // Assume isSuperUser is determined somehow, perhaps from userData
    // const isSuperUser = userData.role === 'superuser';
    //
    //
    // const [stateHistory, setStateHistory] = useState([{
    //     timestamp: new Date(),
    //     state: {
    //         loading: false,
    //         broker: "None",
    //         symbol: "",
    //         strategy: "Put Sale",
    //         selectedPuts: [],
    //         selectedCalls: [],
    //         duration: "Less than 30 days",
    //         expiry: "",
    //         ownership: false,
    //         closeData: {
    //             num_shares: "",
    //             open: true,
    //             order_id: "",
    //             type: "",
    //             amount: "",
    //             price: "",
    //             status: "",
    //             dayb4_close_price: 0,
    //             last_close_price: 0,
    //         },
    //         earningDate: {
    //             next_earning_date: "",
    //             selected_date_pd: "",
    //         },
    //         premium: 1,
    //         premiumData: {
    //             selected_calls: [],
    //             selected_puts: [],
    //             available_strike_1: [],
    //             last_price_1: 0,
    //             type_1: "",
    //             premium_percentage_1: 0,
    //             premium_percentage_threshold_1: 0,
    //             contract_name_1: "",
    //             limit_price_1: 0,
    //             limit_price_2: 0,
    //             available_strike_2: [],
    //             last_price_2: 0,
    //             type_2: "",
    //             premium_percentage_2: 0,
    //             premium_percentage_threshold_2: 0,
    //             contract_name_2: "",
    //         },
    //         filteredPremiumData: {},
    //         quantity1: 1,
    //         quantity2: 1,
    //         strike1: null,
    //         strike2: null,
    //         highestClose: null,
    //         lowestClose: null,
    //         min: undefined,
    //         max: undefined,
    //         result: {
    //             max_gain: 0,
    //             max_loss: 0,
    //             breakeven_points: 0,
    //             x: [],
    //             y: [],
    //         },
    //         orderType: 'limit',
    //         timeInForce: 'gtc'
    //     }
    // }]);
    //
    // useEffect(() => {
    //     const currentState = {
    //         loading,
    //         broker,
    //         symbol,
    //         strategy,
    //         selectedPuts,
    //         selectedCalls,
    //         duration,
    //         expiry,
    //         ownership,
    //         closeData,
    //         earningDate,
    //         premium,
    //         premiumData,
    //         filteredPremiumData,
    //         quantity1,
    //         quantity2,
    //         strike1,
    //         strike2,
    //         highestClose,
    //         lowestClose,
    //         min,
    //         max,
    //         result,
    //         orderType,
    //         timeInForce
    //     };
    //
    //     setStateHistory(prev => [...prev, {timestamp: new Date(), state: currentState}]);
    // }, [loading, broker, symbol, strategy, selectedPuts, selectedCalls, duration, expiry, ownership, closeData, earningDate, premium, premiumData, filteredPremiumData, quantity1, quantity2, strike1, strike2, highestClose, lowestClose, min, max, result, orderType, timeInForce]);

    const CollapsibleItem = ({label, value}) => {
        const [isOpen, setIsOpen] = useState(false);

        const renderValue = (val) => {
            if (Array.isArray(val)) {
                return (
                    <ul>
                        {val.slice(0, 3).map((item, index) => (
                            <li key={index}>{JSON.stringify(item)}</li>
                        ))}
                        {val.length > 3 && <li>... {val.length - 3} more items</li>}
                    </ul>
                );
            } else if (typeof val === 'object' && val !== null) {
                return (
                    <ul>
                        {Object.entries(val).map(([k, v]) => (
                            <CollapsibleItem key={k} label={k} value={v}/>
                        ))}
                    </ul>
                );
            }
            return JSON.stringify(val);
        };




        useEffect(() => {
    setPremiumData(prevData => ({
        ...prevData,
        // Strike 1 related fields
        contract_name_1: filteredPremiumData.contract_name_1 || prevData.contract_name_1,
        last_price_1: filteredPremiumData.last_price_1 || prevData.last_price_1,
        limit_price_1: filteredPremiumData.limit_price_1 || prevData.limit_price_1,
        strike_1: filteredPremiumData.strike_1 || prevData.strike_1,
        // Strike 2 related fields
        contract_name_2: filteredPremiumData.contract_name_2 || prevData.contract_name_2,
        last_price_2: filteredPremiumData.last_price_2 || prevData.last_price_2,
        limit_price_2: filteredPremiumData.limit_price_2 || prevData.limit_price_2,
        strike_2: filteredPremiumData.strike_2 || prevData.strike_2,
    }));
}, [filteredPremiumData]);













        return (
            <div className="collapsible-item">
                <div className="collapsible-header" onClick={() => setIsOpen(!isOpen)}>
                    <span>{isOpen ? '▼' : '▶'}</span>
                    <strong>{label}:</strong>
                    {!isOpen && <span
                        className="preview">{Array.isArray(value) ? `Array(${value.length})` : typeof value}</span>}
                </div>
                {isOpen && <div className="collapsible-content">{renderValue(value)}</div>}
            </div>
        );
    };

    const StateDebugPanel = ({state}) => {
        const [isOpen, setIsOpen] = useState(false);

        return (
            <div className="state-debug-panel">
                <button onClick={() => setIsOpen(!isOpen)}>
                    {isOpen ? 'Hide' : 'Show'} State
                </button>
                {isOpen && (
                    <div className="state-content">
                        {Object.entries(state).map(([key, value]) => (
                            <CollapsibleItem key={key} label={key} value={value}/>
                        ))}
                    </div>
                )}
            </div>
        );
    };


    // to here

    const updatePremiumDataForStrike1 = (updatedData) => {
        setPremiumData(prevData => ({
            ...prevData,
            contract_name_1: updatedData.contractName,
            last_price_1: updatedData.lastPrice,
        }));
        setStrike1(updatedData.strike);
    };


    return (
        <div className="option-analyzer-container">
            <div className="option-analyzer">
                {contextHolder}
                <div>
                    <div className="dropdown-container">
                        {loading ? (
                            <div className="running-container">
                                <img src={Runner} alt="loader" className="runner"/>{" "}
                                <span>RUNNING...</span>
                            </div>

                        ) : null}
                        <NavMenu
                            userData={userData}
                            setUserData={setUserData}
                            setUserToken={setUserToken}
                        />

                    </div>

                    <div className="content-body">
                        <h1>Options Strategy Analyzer</h1>
                        {symbol ? (
                            <>
                                <div className="links">
                                    <div className="link-item">
                                        <a
                                            href={`https://finance.yahoo.com/quote/${symbol}/options`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            View {symbol} on Yahoo Finance
                                        </a>
                                    </div>
                                    <br/>
                                    <div className="link-item">
                                        <a
                                            href={`https://finviz.com/quote.ashx?t=${symbol}`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            View {symbol} on Finviz
                                        </a>
                                    </div>

                                    <br/>

                                    <div className="price-range">
                                        <span className="price-label">Highest Close:</span>
                                        &nbsp;&nbsp;
                                        <span
                                            className="price-value">${highestClose !== null ? highestClose.toFixed(2) : 'N/A'}</span>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <span className="price-label">Lowest Close:</span>
                                        &nbsp;&nbsp;
                                        <span
                                            className="price-value">${lowestClose !== null ? lowestClose.toFixed(2) : 'N/A'}</span>
                                    </div>


                                </div>


                                {closeData ? (
                                    <div
                                        className={`close-price-indicator-wrapper ${
                                            closeData.last_close_price > closeData.dayb4_close_price ? "good-indicator" : "yellow-indicator"
                                        }`}
                                    >
                                        <span className="indicator-message">Day before close: </span>
                                        <span className="indicator-message">{closeData.dayb4_close_price}</span>
                                        &nbsp;&nbsp;
                                        <span className="indicator-message">Last close: </span>
                                        <span className="indicator-message">${closeData.last_close_price}</span>
                                        &nbsp;&nbsp;
                                        {closeData.last_close_price > closeData.dayb4_close_price ? (
                                            <>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                <span className="subtitle">Good: </span>The last price is higher than
                                                the prior day's closing price.
                                            </>
                                        ) : (
                                            <>
                                                <span className="subtitle">Warning: </span>The last price is lower than
                                                the prior day's closing price.
                                            </>
                                        )}

                                    </div>
                                ) : (
                                    <div>
                                        <span>Close data not available</span>
                                    </div>
                                )}


                                {broker === "Alpaca" && symbol && closeData?.num_shares ? (
                                    <div className="basic-info">
                                        {closeData?.num_shares ? (
                                            <p>
                                                <span className="subtitle">Number of shares: </span>
                                                {closeData?.num_shares}
                                            </p>
                                        ) : null}
                                    </div>
                                ) : null}
                                {broker === "Alpaca" &&
                                closeData?.open &&
                                closeData?.num_shares ? (
                                    <>
                                        <div className="red-info-indicator">
                                            <p>
                                                An Open Order exists for {symbol}. Choose another stock
                                                or cancel the existing order before proceeding.
                                            </p>
                                        </div>
                                        <div className="result-details">
                                            <h2>Open Order</h2>
                                            <p>
                                                <span className="subtitle">**Order ID**</span>:{" "}
                                                {closeData?.order_id}
                                            </p>
                                            <p>
                                                <span className="subtitle">**Type**</span>:{" "}
                                                {closeData?.type}
                                            </p>
                                            <p>
                                                <span className="subtitle">**Amount**</span>:{" "}
                                                {closeData?.amount}
                                            </p>
                                            <p>
                                                <span className="subtitle">**Price**</span>: $
                                                {closeData?.price}
                                            </p>
                                            <p>
                                                <span className="subtitle">**Status**</span>:{" "}
                                                {closeData?.status}
                                            </p>
                                            <Button
                                                type="primary"
                                                onClick={handleCloseClick}
                                                danger
                                                style={{marginTop: "10px", marginBottom: "10px"}}
                                            >
                                                Close Order
                                            </Button>
                                        </div>
                                    </>
                                ) : null}
                            </>
                        ) : null}

                        {
                            !closeData?.open &&
                            !(
                                broker === "None" &&
                                ownership === false &&
                                (strategy === "Collar" || strategy === "Covered Call")
                            )
                                ? closeData?.num_shares
                                    ? null
                                    : null
                                : null
                        }
                    </div>


                    <LayoutComponent

                        setSymbol={setSymbol}
                        symbol={symbol}
                        broker={broker}
                        setBroker={setBroker}
                        strategy={strategy}
                        setStrategy={setStrategy}
                        setLoading={setLoading}
                        loading={loading}
                        closeData={closeData}
                        setCloseData={setCloseData}
                        expiry={expiry}
                        setExpiry={setExpiry}
                        ownership={ownership}
                        setOwnership={setOwnership}
                        duration={duration}
                        setDuration={setDuration}
                        earningDate={earningDate}
                        setEarningDate={setEarningDate}
                        premium={premium}
                        setPremium={setPremium}
                        quantity1={quantity1}
                        setQuantity1={setQuantity1}
                        quantity2={quantity2}
                        setQuantity2={setQuantity2}
                        setPremiumData={setPremiumData}
                        premiumData={premiumData}
                        filteredPremiumData={filteredPremiumData}
                        setFilteredPremiumData={setFilteredPremiumData}
                        selectedCalls={selectedCalls}
                        setSelectedCalls={setSelectedCalls}
                        selectedPuts={selectedPuts}
                        setSelectedPuts={setSelectedPuts}
                        strike1={strike1}
                        setStrike1={setStrike1}
                        strike2={strike2}
                        setStrike2={setStrike2}
                        min={min}
                        setMin={setMin}
                        max={max}
                        setMax={setMax}
                        result={result}
                        setResult={setResult}
                    />


                    <hr/>


                    {symbol && (strike1 || strike2) && expiry ? (
                        <div className="realtime-chart">
                            <RealTimeChart
                                ticker={symbol}
                                showSettings={false}
                                put={strike1}
                                call={strike2}
                                expiry={expiry}
                                strategy={strategy}
                                onCloseData={({highestClose, lowestClose}) => {
                                    setHighestClose(highestClose);
                                    setLowestClose(lowestClose);
                                }}
                            />
                        </div>
                    ) : null}

                    <div></div>


                    {symbol && !(broker === "None" && ownership === false && (strategy === "Collar" || strategy === "Covered Call")) ? (
                        <>
                            <div className="strategy-order-container">
                                <div className="radio-group">
                                    <h3>{strategy} </h3>
                                    Order Settings
                                    <h4>Order Type</h4>
                                    <Radio.Group onChange={handleOrderTypeChange} value={orderType}>
                                        <Radio value="limit" className="white-radio">
                                            <span className="white-text">Limit</span>
                                        </Radio>
                                        <Radio value="market" className="white-radio">
                                            <span className="white-text">Market</span>
                                        </Radio>
                                    </Radio.Group>
                                    <h4>Time In Force</h4>
                                    <Radio.Group onChange={handleTimeInForceChange} value={timeInForce}>
                                        <Radio value="gtc" className="white-radio">
                                            <span className="white-text">GTC</span>
                                        </Radio>
                                        <Radio value="eod" className="white-radio">
                                            <span className="white-text">EOD</span>
                                        </Radio>
                                    </Radio.Group>
                                </div>

                                {(strategy === "Collar" || strategy === 'Covered Call') && premiumData?.contract_name_2 && (
                                    <div>
                                        <ul className="list">
                                            <h3 style={{ color: "yellow",  textAlign: 'center' }}>{filteredPremiumData?.type_2 || 'Option'} Leg</h3>

                                            <LegItem label="Contract Name" value={filteredPremiumData?.contract_name_2 || premiumData?.contract_name_2 || 'N/A'} copyable />
                                            <LegItem label="Limit Price" value={filteredPremiumData?.limit_price_2}
                                                copyable />
                                            <LegItem2 label="Expiry Date" value={expiry?.substring(0, 10)}/>
                                            <LegItem2 label="Strike Price" value={`$${strike2}`}/>
                                            <LegItem2 label="Last Price"  value={filteredPremiumData?.last_price_2 ?
                                                    `$${parseFloat(filteredPremiumData.last_price_2).toFixed(2)}` :
                                                    (premiumData?.last_price_2 ? `$${parseFloat(premiumData.last_price_2).toFixed(2)}` : 'N/A') } />
                                            <LegItem2 label="Quantity" value={quantity2?.toString() || '0'}
                                            />
                                        </ul>
                                    </div>
                                )}



                                {(strategy === "Collar" || strategy === 'Put Sale') && premiumData?.contract_name_1 && (
                                    <div>

                                        <ul className="list">
                                            <h3 style={{ color: "yellow", textAlign: 'center' }}>{filteredPremiumData?.type_1 || 'Option'} Leg</h3>

                                            <LegItem label="Contract Name" value={filteredPremiumData?.contract_name_1 || premiumData?.contract_name_1 || 'N/A'} copyable/>
                                            <LegItem label="Limit Price" value={filteredPremiumData?.limit_price_1} copyable/>
                                            <LegItem2 label="Expiry Date" value={expiry?.substring(0, 10)}/>
                                            <LegItem2 label="Strike Price" value={`$${strike1}`}/>
                                            <LegItem2 label="Last Price" value={filteredPremiumData?.last_price_1 ?
                                                    `$${parseFloat(filteredPremiumData.last_price_1).toFixed(2)}` :
                                                    (premiumData?.last_price_1 ? `$${parseFloat(premiumData.last_price_1).toFixed(2)}` : 'N/A') }/>
                                            <LegItem2
                                                label="Quantity" value={quantity1?.toString() || '0'}/>
                                        </ul>
                                    </div>
                                )}

                                {broker === "Alpaca" && (
                                    <div className="order-container">
                                        <h2>Place Order</h2>
                                        <p>Press the button below to execute the options order</p>
                                        <Button type="primary" onClick={handlePlaceClick}>
                                            Place Order
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </>
                    ) : null}


                    {/* option chain table*/}

                    {strategy === "Covered Call" || strategy === "Collar" ? (
                        <div className="collapse">
                            <Collapse defaultActiveKey={[]}>
                                <Panel header={<><span className="premium-step-indicator">5</span> Calls Data</>}
                                       key="1">
                                    <div className="table-container">
                                        <TableComponent data={filteredPremiumData.selected_calls}/>
                                    </div>
                                </Panel>
                            </Collapse>
                        </div>
                    ) : null}
                    {strategy === "Put Sale" || strategy === "Collar" ? (
                        <div className="collapse">
                            <Collapse defaultActiveKey={[]}>
                                <Panel header={<><span className="premium-step-indicator">5</span> Puts Data</>}
                                       key="1">

                                    <div className="table-container">
                                        <TableComponent data={filteredPremiumData.selected_puts}/>
                                    </div>
                                </Panel>
                            </Collapse>
                        </div>
                    ) : null}


                    <div className="disclaimer-container">
                        <div className="disclaimer">
                        <span>
                          Please ensure to check real-time bid/ask prices for the selected
                          options contracts at the time of execution. The provided values
                          may not reflect the current market pricing. This is a learning
                          tool, not a trading platform.
                        </span>
                        </div>
                    </div>
                </div>


                {/*<div>*/}
                {/*    <StateDebugPanel state={{*/}
                {/*        loading,*/}
                {/*        broker,*/}
                {/*        symbol,*/}
                {/*        strategy,*/}
                {/*        selectedPuts,*/}
                {/*        selectedCalls,*/}
                {/*        duration,*/}
                {/*        expiry,*/}
                {/*        ownership,*/}
                {/*        closeData,*/}
                {/*        earningDate,*/}
                {/*        premium,*/}
                {/*        premiumData,*/}
                {/*        filteredPremiumData,*/}
                {/*        quantity1,*/}
                {/*        quantity2,*/}
                {/*        strike1,*/}
                {/*        strike2,*/}
                {/*        highestClose,*/}
                {/*        lowestClose,*/}
                {/*        min,*/}
                {/*        max,*/}
                {/*        result,*/}
                {/*        orderType,*/}
                {/*        timeInForce*/}
                {/*    }}*/}
                {/*     style={{color: 'white'}}/>*/}
                {/*</div>*/}
            </div>
        </div>
    );
};

export default OptionAnalyzer;