import React from 'react';
import { Gauge } from '@ant-design/plots';

const VolatilityGauge = ({ value }) => {
    const config = {
        percent: value,
        range: {
            color: 'l(0) 0:#30BF78 0.5:#FAAD14 1:#F4664A',
        },
        indicator: {
            pointer: {
                style: {
                    stroke: '#D0D0D0',
                },
            },
            pin: {
                style: {
                    stroke: '#D0D0D0',
                },
            },
        },
        statistic: {
            content: {
                style: {
                    fontSize: '36px',
                    lineHeight: '36px',
                },
            },
        },
    };

    return <Gauge {...config} />;
};

export default VolatilityGauge;