import React from 'react';
import ReactECharts from 'echarts-for-react';

// Function to get the value of a CSS variable
const getCSSVariable = (variable) => getComputedStyle(document.documentElement).getPropertyValue(variable).trim();

const PieChart = ({ pieChartData, title }) => {
  // Retrieve the color values from variables.css
  const vibrantGreen = getCSSVariable('--vibrant-green');
  const darkRed = getCSSVariable('--dark-red');
  const textColor = getCSSVariable('--text-color'); // For the legend and label text

  const option = {
    tooltip: {
      trigger: 'item',
    },
    legend: {
      orient: 'horizontal', // Change to horizontal orientation
      left: 'center', // Center horizontally
      top: '15%', // Adjust this value to move the legend down
      itemGap: 10, // Increase gap between items
      textStyle: {
        color: textColor,
        fontSize: 14, // Slightly larger font
        fontFamily: 'Microsoft YaHei',
      },
      formatter: (name) => {
        const item = pieChartData.find((data) => data.name === name);
        return `${name}: ${item.value}`;
      },
      icon: 'circle',  // Use circle icon instead of none
    },


    series: [
      {
        name: 'Results',
        type: 'pie',
        radius: '50%',
        center: ['50%', '50%'], // Push the pie chart to the right to create more space
        data: pieChartData.map((item) => ({
          ...item,
          itemStyle: {
            color: item.name === 'Wins' ? vibrantGreen : darkRed,
          },
          label: {
            show: false, // Turn off the labels on the pie chart itself
          },
          labelLine: {
            show: false, // Disable the lines
          },
        })),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <ReactECharts
        option={option}
        style={{ height: '100%', width: '100%' }}
        opts={{ renderer: 'svg' }}
      />
    </div>
  );
};

export default PieChart;