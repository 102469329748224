import React from 'react';
import { Form, Input, Select, DatePicker, InputNumber, Button, Upload, message, Space } from 'antd';
import { UploadOutlined, PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import './style.css';
import DraggableModal from '../../../Components/DraggableModal/DraggableModal';

const { Option } = Select;
const { TextArea } = Input;

const ManualEntry = ({ onClose }) => {
    const [form] = Form.useForm();

    const onFinish = (values) => {
        console.log('Form values:', values);
        // Handle form submission
        onClose(); // Close the modal after submission
    };

    return (
        <Form form={form} layout="vertical" onFinish={onFinish}>
            <h2>Trade Information</h2>
            <Form.Item name="tradeType" label="Trade Type" rules={[{ required: true }]}>
                <Select placeholder="Select trade type">
                    <Option value="stock">Stock</Option>
                    <Option value="option">Option</Option>
                    <Option value="forex">Forex</Option>
                </Select>
            </Form.Item>

            <Form.Item name="symbol" label="Symbol/Ticker" rules={[{ required: true }]}>
                <Input />
            </Form.Item>

            <Form.List name="transactions">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }) => (
                            <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                <Form.Item
                                    {...restField}
                                    name={[name, 'transactionType']}
                                    rules={[{ required: true, message: 'Missing transaction type' }]}
                                >
                                    <Select placeholder="Transaction Type">
                                        <Option value="buy">Buy</Option>
                                        <Option value="sell">Sell</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'datetime']}
                                    rules={[{ required: true, message: 'Missing date/time' }]}
                                >
                                    <DatePicker showTime />
                                </Form.Item>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'price']}
                                    rules={[{ required: true, message: 'Missing price' }]}
                                >
                                    <InputNumber step="0.01" />
                                </Form.Item>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'quantity']}
                                    rules={[{ required: true, message: 'Missing quantity' }]}
                                >
                                    <InputNumber />
                                </Form.Item>
                                <MinusCircleOutlined onClick={() => remove(name)} />
                            </Space>
                        ))}
                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                Add transaction
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>

            <Form.Item name="strategy" label="Strategy">
                <Input />
            </Form.Item>

            <Form.Item name="notes" label="Trade Notes">
                <TextArea rows={4} />
            </Form.Item>

            <Form.Item name="chartUpload" label="Chart/Screenshot Upload">
                <Upload>
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
            </Form.Item>

            <Form.Item name="chartLink" label="Chart Link">
                <Input />
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Submit Trade
                </Button>
                <Button onClick={onClose} style={{ marginLeft: '10px' }}>
                    Cancel
                </Button>
            </Form.Item>
        </Form>
    );
};

export default ManualEntry;
