import React from 'react';
import { Bar } from '@ant-design/plots';

const BarChart = ({ data, xField, yField, height }) => {
  if (!data || data.length === 0) {
    return <div>No data available for Bar Chart</div>;
  }

  const config = {
    data,
    xField,
    yField,
    height: height || 300,
    // ... other configurations
  };

  return <Bar {...config} />;
};

export default BarChart;