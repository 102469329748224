import {Button, Dropdown, Menu} from "antd";
import React, { useEffect, useState } from "react";
import {SlMenu} from "react-icons/sl";
import {handleLogout} from "../../Utils/UpdateUsersState";
import { Link, useLocation, useNavigate } from "react-router-dom";

import {
    HomeOutlined,
    AppstoreOutlined,
    LogoutOutlined,
  TeamOutlined,
} from "@ant-design/icons";

const NavMenu = ({ userData, setUserData, setUserToken }) => {
    const navigate = useNavigate();

    const handleLogin = () => {
        if (userData?.username) {
            handleLogout(setUserData, setUserToken);
        }
        navigate("/login");
    };



    const menu = (
        <Menu theme="dark" className="menu-dropdown">
            <Menu.Item
                key="home"
                icon={<HomeOutlined/>}
                onClick={() => navigate("/home")}
            >
                Home
            </Menu.Item>
            <Menu.Item
                key="services"
                icon={<AppstoreOutlined/>}
                onClick={() => navigate("/services")}
            >
                Services
            </Menu.Item>
            <Menu.Item
                key="aboutus"
                icon={<TeamOutlined/>}
                onClick={() => navigate("/about-us")}
            >
                About Us
            </Menu.Item>

            <Menu.Item
                key="ourstaff"
                icon={<TeamOutlined/>}  // This is where the icon is applied
                onClick={() => navigate("/our-staff")}
            >
                Our Staff
            </Menu.Item>

            <Menu.Item key="login" onClick={handleLogin} icon={<LogoutOutlined/>}>
                {userData?.username ? "Logout" : "Login"}
            </Menu.Item>


        </Menu>

    );

    return (
        <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
            <Button
                style={{
                    backgroundColor: "transparent",
                    border: "none",
                }}
                className="options"
                icon={<SlMenu style={{fontSize: "20px"}}/>}
            />
        </Dropdown>
    );
};

export default NavMenu;