import React, {useState} from "react";
import "./style.css";
import Footer from "../../Components/Footer";
import Navbar from "../../Components/Navbar";
import {Row, Col, Select} from 'antd';
import imageCollage from '../../Assets/Images/zh_collage.PNG';
import alZerohour from '../../Assets/Images/al_zerohour.png';

const {Option} = Select;

const AboutUs = ({userData, setUserData, setUserToken}) => {


    const videoUrl1 = "https://www.youtube.com/embed/vxlPY01iuRY"; // Static URL for robbie welcome
    const videoUrl2 = "https://www.youtube.com/embed/P7qOeC--m38"; // Static URL for analysts scorecard


    return (
        <div>
            <Navbar userData={userData} setUserData={setUserData} setUserToken={setUserToken}/>

            <div className="about-us-section">
                <h1 className="page-title">About Us</h1>
                <div className="line">We are a boutique consulting firm delivering targeted, high-quality, tailored capabilities through software for select clientele.</div>
                <p>
                    Our teams have created systems that have greatly enhanced the capabilities of my clients during
                    mission-critical situations and events.
                </p>
                <p>
                    We've designed, developed, and delivered innovative solutions and services at an impressive speed,
                    and, a few times, at near magical scale during some of the most trying of situations. By
                    orchestrating teams of very talented, very dedicated and very focused specialists.
                </p>
                <hr/>
                <Row gutter={16} className="about-us-container">
                    <Col xs={24} md={12} className="col-left">
                        <ul>
                            <br />
                            <br />
                            <li>Global duty of care personnel security systems</li>
                            <li>State level emergency medical ESAR-VIP operations</li>
                            <li>Olympic level event group security communication and logistics</li>
                            <li>Mass event asset deployment mapping and situational awareness dashboards</li>
                            <li>Hurricane preparedness operations for cities, counties and homeland security agencies
                            </li>
                        </ul>
                    </Col>
                    <Col xs={24} md={12} className="col-right">
                        <img
                            src={imageCollage}
                            alt="Collage of software"
                            className="about-us-image"
                        />
                    </Col>
                </Row>

                <hr/>

                <br/>
                <strong>About Automation:</strong>
                <br/>
                <ul className="custom-spacing">
                    <p>
                        In 2022 we ran a research project which engaged a professionally managed 'trading bot'. As we grew to understand the providers' perspective of automated trading we realized it as a hybrid form of automation which requires human intervention to run the bot over an extended period of time. Our excitement quickly faded. The project failed to meet our expectations in the end.
                    </p>
                    <p>
                        The lessons learned from that experience helped to inform our understanding of many of the 'automated' trading systems widely promoted. And it helped shape our vision of gap bridging capabilities. In the aggregate, these capabilities will provide what we call "Market Awareness", the ability to autonomously analyze diverse datasets and real-time metrics, allowing the system to determine the relevance and suitability of different trading strategies based on its own perception of actual market conditions of the day.
                    </p>
                    <hr/>

                    <hr/>
                    <Row gutter={16} className="ai-testing-container">
                        <Col xs={24} md={12} className="ai-image-panel">
                            <img
                                src={alZerohour}
                                alt="Al Zerohour"
                                className="ai-testing-image"
                            />
                        </Col>
                        <Col xs={24} md={12} className="ai-text-panel">
                            <p>
                                The goal was to develop a sophisticated trading assistant which performs at an equivalent of an experienced human pattern trader. We had completed the full trade life cycle framework and had begun to turn our
                                focus on strategy optimization when ChatGPT was released.
                            </p>
                            <p style={{textAlign: 'center', fontWeight: 'bold'}}>

                            </p>
                        </Col>
                    </Row>
                    <p style={{textAlign: 'center', fontWeight: 'bold'}}>
                        When ChatGPT hit the market everything went crazy and our outcome expectations accelerated at an unbelievable pace across the entire spectrum of our project engagement. It was mind blowing as we dove head first into this new technology and realized how we can leverage it in a practical way in the system.
                    </p>

                    <hr/>
                    <div></div>
                    <strong>The Art of Infused - Artificial Expertise:</strong>
                    <br/>
                    <div>
                        <p>We rode the wave for almost 8 months after OpenAi introduced its
                            APIs. Our testing, focused on targeted outcomes, brought about some really eye popping results. What struck us was the ability for us to create workflows to consume volumes of disparate information and quickly translate that into accurate and actionable advice. </p>
                    </div>
                    <p>The level of human experience and knowledge required to process that data and extrapolate it into actionable and understandable advice are at a level in the order of decades of learning beyond the knowledge I possess. </p>
                    <p>The effect and the capabilities they created can only be described as Artificial Expertise. It is astounding to put it mildly.</p>


                    <hr/>
                    {/*<br/> /!* This adds a blank line *!/*/}


                    <strong>Robbie - Our Projects:</strong>
                    <p></p>
                    <div>
                        {/* welcome to robbie*/}
                        <Row gutter={16} className="ai-testing-container">
                            <Col xs={24} md={12} className="ai-image-panel">
                                <div>
                                    <iframe
                                        width="100%"
                                        height="200"
                                        src={videoUrl1}
                                        title="YouTube video player"
                                        frameBorder="1"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        referrerPolicy="strict-origin-when-cross-origin"
                                        allowFullScreen
                                    ></iframe>
                                </div>
                            </Col>
                            <Col xs={24} md={12} className="ai-text-panel">
                                <p>

                                    Robbie is our autonomous, fully functioning trading system that is evolving with analysts specializing in different domains.
                                    <p>
                                        In Phantom Works, Robbie executes trades each designed to achieve success under specific market conditions. By continuously processing market data in real-time, Robbie can gain a deep understanding of the current market state. And by maintaining his phantom journal, he is able to track and evaluate the performance of strategies he employs in real-time giving the staff a greater ability to produce relevant advice.
                                    </p>
                                </p>
                                <p style={{textAlign: 'center', fontWeight: 'bold'}}>
                                    {/* Additional text can go here */}
                                </p>
                            </Col>
                        </Row>


                        {/*<Row gutter={16} className="ai-testing-container">*/}
                        {/*    <Col xs={24} md={12} className="ai-text-panel">*/}
                        {/*        <p>*/}
                        {/*            Through the synthesis of market data, the strategy library, and trade journal, we are working to develop "Market Awareness". This capability will enable Robbie to dynamically select the most appropriate trading strategy based on his assessment of the prevailing market conditions. As market conditions evolve, Robbie adaptively switches to strategies that he determines are likely to be more effective, drawing on his analysis and the historical performance data at his disposal.*/}
                        {/*        </p>*/}

                        {/*        <p style={{textAlign: 'center', fontWeight: 'bold'}}>*/}
                        {/*            /!* Additional text can go here *!/*/}
                        {/*        </p>*/}
                        {/*    </Col>*/}
                        {/*    <Col xs={24} md={12} className="ai-image-panel">*/}
                        {/*        <div>*/}
                        {/*            <iframe*/}
                        {/*                width="100%"*/}
                        {/*                height="200"*/}
                        {/*                src={videoUrl2}*/}
                        {/*                title="YouTube video player"*/}
                        {/*                frameBorder="1"*/}
                        {/*                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"*/}
                        {/*                referrerPolicy="strict-origin-when-cross-origin"*/}
                        {/*                allowFullScreen*/}
                        {/*            ></iframe>*/}
                        {/*        </div>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                    </div>

                    {/*<p>*/}
                    {/*    By integrating "Market Awareness" into Robbie's core functionality, we are creating an*/}
                    {/*    autonomous trading system that can optimize its performance and success in the face of*/}
                    {/*    ever-changing market landscapes. Robbie's ability to make informed, data-driven decisions*/}
                    {/*    without the need for manual intervention sets him apart as a cutting-edge trading assistant.*/}
                    {/*    These are some of those capabilities on our workbench.*/}
                    {/*</p>*/}

                </ul>

                <strong>Meanwhile, in the World of Humans:</strong>
                <div>
                    <p>
                        In a completely unexpected set of events a professional trader described a software tool that he thought would be of a real benefit for his community of traders.
                        That  conversation launched a project to create the Options Analyzer which is in Beta testing.
                    </p>
                </div>
                <hr/>

                {/*<p>*/}
                {/*    The apps and pages you see here are dashboards reflecting Robbie's internal processes. Each*/}
                {/*    new app we launch marks another milestone, showcasing the tools, logic, and data processes at work. <br />*/}
                {/*    Our design aims to achieve Market Awareness, with functions and capabilities adding a unique layer*/}
                {/*    of consideration and perspective.*/}
                {/*</p>*/}
            <p>
                Exciting times!
            </p>
                <p>
                    I pray the best for your journey.
                    {/*<hr/>*/}
                </p>

               <div>
                <p>
                    "There is nothing that can happen to you that you cannot do something about, even
                    if it's only to change your attitude so it doesn't destroy your spirit."
                    <br/> — Napoleon Hill
                </p>
            </div>
            </div>

            <Footer/>
        </div>
    );
};

export default AboutUs;