import axios from "axios";
import {getToken} from "../../Utils/UpdateUsersState";

const test = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/test/`,
      {}
    );
    return res;
  } catch (e) {
    console.log(e, "error");
    return e;
  }
};

const closeOrder = async (symbol) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/analyzer/cancel/`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
        params: {
          symbol: symbol,
        },
      }
    );
    return res;
  } catch (e) {
    console.error("Error cancel order: ", e);
    return e?.response;
  }
};

const startAnalyzer = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/analyzer/start/`,
      data,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return res;
  } catch (e) {
    console.log(e, "error");
    return e?.response;
  }
};

const getExpiry = async (symbol, duration) => {
  try {
    return await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/analyzer/expiry/`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
        params: {
          symbol: symbol,
          trade_duration: duration,
        },
      }
    );
  } catch (e) {
    console.error("Error get expiry: ", e);
    return e?.response;
  }
};

const getBoxData = async (symbol, expiry) => {
  try {
    return await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/analyzer/box/`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
        params: {
          symbol: symbol,
          expiry: expiry,
        },
      }
    );
  } catch (e) {
    console.error("Error get expiry: ", e);
    return e?.response;
  }
};

const postPremium = async (data) => {
  try {
    return await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/analyzer/premium/`,
      data,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
  } catch (e) {
    console.log(e, "error");
    return e?.response;
  }
};

const getResultData = async (min, max, strategy, data) => {
  try {
    return await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/analyzer/result/`,
      data,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
        params: {
          min_price: min,
          max_price: max,
          strategy: strategy,
        },
      }
    );
  } catch (e) {
    console.error("Error get expiry: ", e);
    return e?.response;
  }
};

const postPlaceOrder = async (strategy, data) => {
  try {
    return await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/analyzer/place/`,
      data,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
        params: {
          strategy: strategy,
        },
      }
    );
  } catch (e) {
    console.error("Error get expiry: ", e);
    return e?.response;
  }
};

const realTimeChartApi = async (ticker, timeframe, emaPeriod, rsiPeriod) => {
  try {
    return await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/data/${ticker}/${timeframe}/${emaPeriod}/${rsiPeriod}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
  } catch (e) {
    console.error("Error get expiry: ", e);
    return e?.response;
  }
};

export {
  test,
  closeOrder,
  startAnalyzer,
  getExpiry,
  getBoxData,
  postPremium,
  getResultData,
  postPlaceOrder,
  realTimeChartApi,
};