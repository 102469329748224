import React from 'react';
import ReactECharts from 'echarts-for-react';

const BarChart = ({ barData, xAxisData, title }) => {
  const option = {
    title: {
      text: title || 'Bar Chart',
      left: 'center',
    },
    tooltip: {
      trigger: 'axis',
    },
    xAxis: {
      type: 'category',
      data: xAxisData,
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        type: 'bar',
        data: barData,
      },
    ],
  };

  return <ReactECharts option={option} style={{ height: '400px', width: '100%' }} />;
};

export default BarChart;