import { makeApiRequest } from '../makeApiRequest'; // Import makeApiRequest
import { getToken } from "../../Utils/UpdateUsersState";

export const importTrades = async (formData, onProgressUpdate, onImportComplete) => {
  try {
    console.log('FormData being sent:');
    for (let [key, value] of formData.entries()) {
      console.log(key, value instanceof File ? `File: ${value.name}` : value);
    }

    const response = await makeApiRequest('post', '/api/tradejournal/import/', formData);

    if (response.data && response.data.import_id) {
      // Start polling for import status
      await pollImportStatus(response.data.import_id, onProgressUpdate);
    } else if (response.data && response.data.message) {
      // Import completed immediately
      onProgressUpdate({ status: 'Completed', progress: 100, message: response.data.message });
    }

    // Call onImportComplete to close the modal
    if (onImportComplete) {
      onImportComplete();
    }

    return response;
  } catch (e) {
    console.error("Error in importTrades:", e);
    if (e.response) {
      console.error('Error response:', e.response.data);
    }
    throw e;
  }
};

const pollImportStatus = async (importId, onProgressUpdate) => {
  return new Promise((resolve, reject) => {
    const pollInterval = setInterval(async () => {
      try {
        const statusResponse = await makeApiRequest('get', `/api/tradejournal/import-status/${importId}/`);
        const statusData = statusResponse.data;

        if (onProgressUpdate && typeof onProgressUpdate === 'function') {
          onProgressUpdate(statusData);
        }

        if (statusData.status === 'Completed' || statusData.status === 'Error') {
          clearInterval(pollInterval);
          resolve(statusData);
        }
      } catch (error) {
        console.error('Error polling import status:', error);
        clearInterval(pollInterval);
        reject(error);
      }
    }, 2000); // Poll every 2 seconds
  });
};

export const getImportStatus = async (importId) => {
  try {
    const response = await makeApiRequest('get', `/api/tradejournal/import-status/${importId}/`);
    return response.data;
  } catch (error) {
    console.error('Error getting import status:', error);
    throw error;
  }
};