import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';

const DoubleLineChart = ({ grossData, netData, xAxisData, yAxisMin, yAxisMax, yAxisTickSize, title }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const myChart = echarts.init(chartRef.current);

    // Calculate the maximum and minimum values from grossData and netData
    const maxValue = Math.max(...grossData.map(d => d.value), ...netData.map(d => d.value));
    const minValue = Math.min(...grossData.map(d => d.value), ...netData.map(d => d.value));
    
    // Calculate the range
    const range = maxValue - minValue;

    // Determine the appropriate tick interval
    let tickInterval;
    if (range <= 5000) {
      tickInterval = 1000;
    } else if (range <= 50000) {
      tickInterval = 5000;
    } else {
      tickInterval = 10000;
    }

    // Adjust the min to ensure at least one tick below the opening balance
    const adjustedMin = Math.floor(minValue / tickInterval) * tickInterval;

    const option = {
      // title: {
      //   text: title || 'Portfolio Value Over Time',
      //   left: 'center'
      // },
      tooltip: {
        trigger: 'axis',
        formatter: function (params) {
          const data = params[0].data;
          if (!data) return '';  // Return empty string if data is undefined

          const formatValue = (value) => {
            return value !== undefined ? `$${value.toLocaleString()}` : 'N/A';
          };

          const formatNumber = (value) => {
            return value !== undefined ? value.toString() : 'N/A';
          };

          return `
            <strong>${params[0].axisValue || 'N/A'}</strong><br/>
            Portfolio Value: ${formatValue(data.value)}<br/>
            Daily P/L: ${formatValue(data.dailyProfitLoss)}<br/>
            Trades: ${formatNumber(data.numTrades)}<br/>
            Win/Loss: ${formatNumber(data.winningTrades)}/${formatNumber(data.losingTrades)}<br/>
            Largest Win: ${formatValue(data.largestWin)}<br/>
            Largest Loss: ${formatValue(data.largestLoss)}<br/>
            Total Fees: ${formatValue(data.totalFees)}<br/>
            Most Traded: ${data.mostTradedSymbol || 'N/A'}<br/>
            Open Positions: ${formatNumber(data.openPositions)}<br/>
            Realized P/L: ${formatValue(data.realizedPL)}<br/>
            Unrealized P/L: ${formatValue(data.unrealizedPL)}
          `;
        }
      },
      xAxis: {
        type: 'category',
        data: xAxisData,
        axisLine: {
          show: true
        },
        axisTick: {
          show: true
        },
        axisLabel: {
          show: true
        }
      },
      yAxis: {
        type: 'value',
        min: adjustedMin,
        max: maxValue,
        interval: tickInterval,
        axisLine: {
          show: true  // This will show the y-axis line
        },
        axisTick: {
          show: true  // This will show the y-axis ticks
        },
        axisLabel: {
          show: true,
          formatter: (value) => {
            if (value >= 1000000) {
              return `$${(value / 1000000).toFixed(1)}M`;
            } else if (value >= 1000) {
              return `$${(value / 1000).toFixed(0)}K`;
            } else {
              return `$${value.toFixed(0)}`;
            }
          },
          color: 'rgba(255, 255, 255, 0.7)'  // Adjust this color to match your theme
        },
        splitLine: {
          show: true,
          lineStyle: {
            type: 'dashed',
            color: 'rgba(255, 255, 255, 0.3)'  // This matches the default light theme x-axis color
          }
        }
      },
      series: [
        {
          name: 'Gross',
          type: 'line', 
          data: grossData,
          smooth: true
        },
        {
          name: 'Net',
          type: 'line',
          data: netData,
          smooth: true
        }
      ],
      dataZoom: [
        {
          type: 'inside',
          xAxisIndex: 0,
          filterMode: 'none'
        },
        {
          type: 'inside',
          yAxisIndex: 0,
          filterMode: 'none'
        }
      ],
      toolbox: {
        feature: {
          restore: {},
          saveAsImage: {}
        }
      }
    };

    myChart.setOption(option);

    return () => myChart.dispose();
  }, [grossData, netData, xAxisData, yAxisMin, yAxisMax, yAxisTickSize, title]);

  return <div ref={chartRef} style={{ width: '100%', height: '400px' }}></div>;
};

export default DoubleLineChart;