import React from 'react';
import { Card, Statistic } from 'antd';

const SharpeRatioDisplay = ({ value }) => {
  const color = value >= 1 ? '#3f8600' : value >= 0 ? '#faad14' : '#cf1322';

  return (
    <Card>
      <Statistic
        title="Sharpe Ratio"
        value={value.toFixed(2)}
        precision={2}
        valueStyle={{ color }}
        suffix={value >= 1 ? '👍' : value >= 0 ? '😐' : '👎'}
      />
    </Card>
  );
};

export default SharpeRatioDisplay;
