import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { DatePicker, Button, Card, Table, Select, Spin, message, Image } from 'antd';
import moment from 'moment';
import { getTrades } from '../../../API/tradeJournal';
import './style.css';

const { RangePicker } = DatePicker;
const { Option } = Select;

const StocksJournal = ({ userToken, refreshTrigger, onRefreshComplete }) => {
  const [journalData, setJournalData] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [chartMetrics, setChartMetrics] = useState({ x: 'trade_entry_time', y: 'price' });
  const [loading, setLoading] = useState(true);

  const fetchJournalData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getTrades();
      
      if (!Array.isArray(response)) {
        throw new Error('Invalid response format');
      }

      setJournalData(response);
      
      if (refreshTrigger) {
        onRefreshComplete();
      }
    } catch (error) {
      console.error('Error fetching trades:', error);
      message.error('Failed to load trades');
    } finally {
      setLoading(false);
    }
  }, [refreshTrigger, onRefreshComplete]);

  useEffect(() => {
    fetchJournalData();
  }, [fetchJournalData, userToken]);

  const filteredTrades = useMemo(() => {
    return journalData
      .filter(trade => trade.asset_type === 'stock')
      .filter(trade => {
        if (!dateRange[0] || !dateRange[1]) return true;
        const tradeDate = moment(trade.trade_entry_time);
        return tradeDate.isBetween(dateRange[0], dateRange[1], null, '[]');
      });
  }, [journalData, dateRange]);

  const summaryMetrics = useMemo(() => {
    const totalGainLoss = filteredTrades.reduce((sum, trade) => {
      if (trade.exit_price && trade.price) {
        const tradeGainLoss = ((trade.exit_price - trade.price) * trade.quantity) - 
                              (parseFloat(trade.fees) + parseFloat(trade.commission) + parseFloat(trade.slippage));
        return sum + tradeGainLoss;
      }
      return sum;
    }, 0);
    const winningTrades = filteredTrades.filter(trade => trade.trade_result === 'win');
    const winRate = filteredTrades.length > 0 ? (winningTrades.length / filteredTrades.length) * 100 : 0;

    return {
      totalGainLoss: totalGainLoss.toFixed(2),
      winRate: winRate.toFixed(2),
      tradeCount: filteredTrades.length
    };
  }, [filteredTrades]);

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
  };

  const handleDatePreset = (preset) => {
    const today = moment();
    let start, end;
    switch (preset) {
      case 'today':
        start = today.startOf('day');
        end = today.endOf('day');
        break;
      case 'thisWeek':
        start = today.startOf('week');
        end = today.endOf('week');
        break;
      case 'thisMonth':
        start = today.startOf('month');
        end = today.endOf('month');
        break;
      case 'thisQuarter':
        start = today.startOf('quarter');
        end = today.endOf('quarter');
        break;
      case 'thisYear':
        start = today.startOf('year');
        end = today.endOf('year');
        break;
      default:
        start = null;
        end = null;
    }
    setDateRange([start, end]);
  };

  const handleMetricChange = (value, axis) => {
    setChartMetrics(prev => ({ ...prev, [axis]: value }));
  };

  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    { title: 'Symbol', dataIndex: 'symbol', key: 'symbol' },
    { title: 'Broker', dataIndex: 'broker', key: 'broker' },
    { title: 'Asset Type', dataIndex: 'asset_type', key: 'asset_type' },
    { title: 'Strategy', dataIndex: 'strategy', key: 'strategy' },
    { title: 'Trade Type', dataIndex: 'trade_type', key: 'trade_type' },
    { 
      title: 'Entry Price', 
      dataIndex: 'price', 
      key: 'price',
      render: (value) => value ? `$${parseFloat(value).toFixed(2)}` : 'N/A'
    },
    { 
      title: 'Exit Price', 
      dataIndex: 'exit_price', 
      key: 'exit_price',
      render: (value) => value ? `$${parseFloat(value).toFixed(2)}` : 'N/A'
    },
    { 
      title: 'Stop Loss', 
      dataIndex: 'stop_loss', 
      key: 'stop_loss',
      render: (value) => value ? `$${parseFloat(value).toFixed(2)}` : 'N/A'
    },
    { 
      title: 'Take Profit', 
      dataIndex: 'take_profit', 
      key: 'take_profit',
      render: (value) => value ? `$${parseFloat(value).toFixed(2)}` : 'N/A'
    },
    { 
      title: 'Entry Time', 
      dataIndex: 'trade_entry_time', 
      key: 'trade_entry_time',
      render: (text) => text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : 'N/A'
    },
    { 
      title: 'Exit Time', 
      dataIndex: 'exit_date', 
      key: 'exit_date',
      render: (text) => text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : 'N/A'
    },
    { title: 'Result', dataIndex: 'trade_result', key: 'trade_result' },
    { 
      title: 'Chart', 
      dataIndex: 'chart_image', 
      key: 'chart_image',
      render: (text) => text ? <Image src={text} width={50} /> : 'N/A'
    },
  ];

  // Add logging to inspect the data being passed to the charts
  useEffect(() => {
    console.log('Filtered Trades:', filteredTrades);
    console.log('Chart Metrics:', chartMetrics);
  }, [filteredTrades, chartMetrics]);

  if (loading) {
    return <Spin size="large" />;
  }

  return (
    <div className="stocks-journal">
      <h1>Trade Journal</h1>
      
      <div className="date-range-picker">
        <RangePicker onChange={handleDateRangeChange} value={dateRange} />
        <Button onClick={() => handleDatePreset('today')}>Today</Button>
        <Button onClick={() => handleDatePreset('thisWeek')}>This Week</Button>
        <Button onClick={() => handleDatePreset('thisMonth')}>This Month</Button>
        <Button onClick={() => handleDatePreset('thisQuarter')}>This Quarter</Button>
        <Button onClick={() => handleDatePreset('thisYear')}>This Year</Button>
        <Button onClick={() => setDateRange([null, null])}>Clear</Button>
      </div>

      <div className="summary-cards">
        <Card title="Total Gain/Loss">
          ${summaryMetrics.totalGainLoss}
        </Card>
        <Card title="Win Rate">
          {summaryMetrics.winRate}%
        </Card>
        <Card title="Trade Count">
          {summaryMetrics.tradeCount}
        </Card>
      </div>

      {/* Commenting out the chart-related code */}
      {/* <div className="charts">
        <BarChart data={filteredTrades} xAxis={chartMetrics.x} yAxis={chartMetrics.y} />
        <PieChart data={filteredTrades} />
        <div className="chart-controls">
          <Select value={chartMetrics.x} onChange={(value) => handleMetricChange(value, 'x')}>
            <Option value="trade_entry_time">Entry Time</Option>
            <Option value="strategy">Strategy</Option>
            <Option value="symbol">Symbol</Option>
            <Option value="broker">Broker</Option>
          </Select>
          <Select value={chartMetrics.y} onChange={(value) => handleMetricChange(value, 'y')}>
            <Option value="price">Entry Price</Option>
            <Option value="exit_price">Exit Price</Option>
            <Option value="quantity">Quantity</Option>
            <Option value="fees">Fees</Option>
            <Option value="slippage">Slippage</Option>
          </Select>
        </div>
      </div> */}

      <Table
        dataSource={filteredTrades}
        columns={columns}
        rowKey="trade_id"
        className="stocks-journal-table"
        pagination={{
          pageSize: 10,
          showSizeChanger: true,
          showQuickJumper: true,
        }}
      />
    </div>
  );
};

export default StocksJournal;