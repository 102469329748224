import React, {useEffect, useRef, useState} from "react";
import {createChart, CrosshairMode} from "lightweight-charts";
import {Col, Input, Row, Select} from "antd";
import {realTimeChartApi} from "../../../API/analyzer";
import "./style.css";

const {Option} = Select;

const ChartLegend = ({intervalTime, setIntervalTime, indicators, expiryDate, call, put, showSettings}) => (
    <Row className="chart-legend" gutter={[16, 16]} style={{marginBottom: "20px"}}>
        <Col>
            <label style={{color: "white"}} htmlFor="interval">
                Timeframe:{" "}
            </label>
            {showSettings ? (
                <Select
                    id="interval"
                    value={intervalTime}
                    onChange={(value) => setIntervalTime(value)}
                    style={{width: 60}}
                >
                    <Option value="5m">5m</Option>
                    <Option value="15m">15m</Option>
                    <Option value="1h">1h</Option>
                    <Option value="1d">1d</Option>
                    <Option value="1wk">1wk</Option>
                    <Option value="1mo">1mo</Option>
                </Select>
            ) : (
                <span style={{color: "white"}}>Daily</span>
            )}
        </Col>
        {indicators.map((indicator, index) => (
            <Col key={index}>
                <span style={{color: indicator.color, marginRight: '8px'}}>■</span>
                <span style={{color: 'white'}}>{indicator.name}</span>
            </Col>
        ))}
        {expiryDate && (
            <Col>
                <span style={{color: 'white', marginRight: '8px'}}>|</span>
                <span style={{color: 'white'}}>Expiry: {new Date(expiryDate).toLocaleDateString()}</span>
            </Col>
        )}
        {call && (
            <Col>
                <span className="legend-color" style={{backgroundColor: 'green', marginRight: '8px'}}></span>
                <span style={{color: 'white'}}>Call: ${call}</span>
            </Col>
        )}
        {put && (
            <Col>
                <span className="legend-color" style={{backgroundColor: 'orange', marginRight: '8px'}}></span>
                <span style={{color: 'white'}}>Put: ${put}</span>
            </Col>
        )}
    </Row>
);


const RealTimeChart = ({ticker, showSettings, expiry, call, put, strategy, onCloseData}) => {
    const chartContainerRef = useRef(null);
    const chartRef = useRef(null);
    const seriesRef = useRef(null);
    const emaSeries = useRef(null);
    const sma100Series = useRef(null);
    const sma200Series = useRef(null);
    const verline = useRef(null);
    const callPriceLineRef = useRef(null);
    const putPriceLineRef = useRef(null);

    // const volumeSeriesRef = useRef(null);


    const [highestClose, setHighestClose] = useState(null);
    const [lowestClose, setLowestClose] = useState(null);
    const [maxHigh, setMaxHigh] = useState(null);
    const [minlow, setMinLow] = useState(null);


    const [intervalTime, setIntervalTime] = useState("1d");
    const [ema, setEma] = useState(20);
    const [rsi, setRsi] = useState(14);
    const [frequency, setFrequency] = useState(10);
    const indicators = [
        {name: '10 EMA', color: 'rgba(255, 255, 0, 1)'},
        {name: '100 SMA', color: 'rgba(0, 0, 255, 1)'},
        {name: '200 SMA', color: 'rgba(255, 0, 0, 1)'},
    ];

    const fetchData = async (ticker, timeframe, emaPeriod, rsiPeriod) => {
        console.log(ticker, timeframe, emaPeriod, rsiPeriod);

        try {
            const res = await realTimeChartApi(
                ticker,
                timeframe,
                emaPeriod,
                rsiPeriod,
            );



            if (res?.data?.candlestick && res.data.candlestick.length > 0 && expiry) {
                const lastDataPoint = new Date(res.data.candlestick[res.data.candlestick.length - 1].time * 1000);
                const expiryDate = new Date(expiry);
                const whitespaceStart = new Date(lastDataPoint.getTime() + 86400000); // Add one day
                // const whitespaceEnd = new Date(expiryDate.getTime() + 432000000); // Add 5 days to expiry
                const whitespaceEnd = new Date(expiryDate.getTime() + 864000000); // Add 10 days to expiry


                let whitespaceData = [];
                let currentDate = whitespaceStart;
                while (currentDate <= whitespaceEnd) {
                    whitespaceData.push({time: Math.floor(currentDate.getTime() / 1000)});
                    currentDate.setDate(currentDate.getDate() + 1);
                }

                res.data.candlestick = [...res.data.candlestick, ...whitespaceData];
            }

            // Calculate the timestamp for 90 days ago
            const today = new Date();
            const ninetyDaysAgo = Math.floor((today.getTime() - (360 * 24 * 60 * 60 * 1000)) / 1000);
            // Filter each dataset
            const filteredCandlestick = res?.data?.candlestick.filter(data => data.time >= ninetyDaysAgo);
            const filteredEma = res?.data?.ema.filter(data => data.time >= ninetyDaysAgo);
            const filteredSma100 = res?.data?.sma100.filter(data => data.time >= ninetyDaysAgo);
            const filteredSma200 = res?.data?.sma200.filter(data => data.time >= ninetyDaysAgo);

            // Use the max_high and min_low values provided by the backend
            const highestValue = res.data.max_high;
            const lowestValue = res.data.min_low;
            const maxHigh = res.data.max_high;
            const minLow = res.data.min_low;

            // Create volumeData from filteredCandlestick
        // const volumeData = filteredCandlestick.map(candle => ({
        //     time: candle.time,
        //     value: candle.volume,
        //     color: candle.close > candle.open ? 'rgba(3, 81, 8, 0.8)' : 'rgba(182, 14, 29, 0.8)'
        // }));


        // Set the candlestick data
        seriesRef.current.setData(filteredCandlestick);

        // Set the volume data
        // volumeSeriesRef.current.setData(volumeData);

            console.log('Received highest value:', highestValue);
            console.log('Received lowest value:', lowestValue);

            // Update state
            setHighestClose(highestValue);
            setLowestClose(lowestValue);
            setMaxHigh(res.data.max_high);
            setMinLow(res.data.min_low);


            // Pass data to parent component
            if (onCloseData) {
                onCloseData({highestClose: highestValue, lowestClose: lowestValue});
            }

            // seriesRef.current = chartRef.current.addCandlestickSeries({
            //     priceLineVisible: false,
            //     priceLineWidth: 2,
            //     priceLineColor: '#4682B4',
            //     priceLineStyle: 0,
            //     lastValueVisible: true,
            //     upColor: '#035108',        // Green for upward candles
            //     downColor: '#b60e1d',      // Red for downward candles
            //     borderUpColor: '#035108',  // Green border for upward candles
            //     borderDownColor: '#b60e1d',// Red border for downward candles
            //     wickUpColor: '#035108',    // Green wick for upward candles
            //     wickDownColor: '#b60e1d',  // Red wick for downward candles
            // });

            // Set the filtered data to the chart series
            seriesRef.current.setData(filteredCandlestick);
            emaSeries.current.setData(filteredEma);
            sma100Series.current.setData(filteredSma100);
            sma200Series.current.setData(filteredSma200);
            // Fit content to the chart
            chartRef.current.timeScale().fitContent();

        } catch (e) {
            console.error("Error get expiry: ", e);
        }
    };

    useEffect(() => {
        chartRef.current = createChart(chartContainerRef.current, {
            width: chartContainerRef.current.clientWidth,
            height: 400,
            layout: {
                background: {color: "transparent"},
                textColor: "#fff",
            },
            grid: {
                vertLines: {
                    color: "rgba(255, 255, 255, 0.1)",
                    style: 1,
                    visible: true,
                },
                horzLines: {
                    color: "rgba(255, 255, 255, 0.1)",
                    style: 1,
                    visible: true,
                },
            },
            crosshair: {
                mode: CrosshairMode.Normal,
            },
            timeScale: {
                visible: true,
            },
        });

        seriesRef.current = chartRef.current.addCandlestickSeries({
            priceLineVisible: false,
            priceLineWidth: 2,
            priceLineColor: '#4682B4',
            priceLineStyle: 0,
            lastValueVisible: true,
            upColor: '#035108',        // Green for upward candles
            downColor: '#b60e1d',      // Red for downward candles
            borderUpColor: '#035108',  // Green border for upward candles
            borderDownColor: '#b60e1d',// Red border for downward candles
            wickUpColor: '#035108',    // Green wick for upward candles
            wickDownColor: '#b60e1d',  // Red wick for downward candles
        });
        emaSeries.current = chartRef.current.addLineSeries({
            color: 'rgba(255, 255, 0, 1)',
            lineWidth: 2,
            lastValueVisible: false,
            priceLineVisible: false,
        });

        sma100Series.current = chartRef.current.addLineSeries({
            color: 'rgba(0, 0, 255, 1)',
            lineWidth: 2,
            lastValueVisible: false,
            priceLineVisible: false,
        });

        sma200Series.current = chartRef.current.addLineSeries({
            color: 'rgba(255, 0, 0, 1)',
            lineWidth: 2,
            lastValueVisible: false,
            priceLineVisible: false,
        });


        // Add this in your main useEffect, after creating other series
        // volumeSeriesRef.current = chartRef.current.addHistogramSeries({
        //     color: 'rgba(76, 175, 40, 0.5)',
        //     priceFormat: {
        //         type: 'volume',
        //     },
        //     priceScaleId: '', // Set as overlay
        // });
        //
        // // Add this after creating the volume series in useEffect
        // volumeSeriesRef.current.priceScale().applyOptions({
        //     scaleMargins: {
        //         top: 0.7, // highest point of the series will be 70% away from the top
        //         bottom: 0,
        //     },
        // });




        if (expiry) {
            const expiryDate = new Date(expiry);

            let dateObject = new Date(Date.UTC(expiryDate.getFullYear(), expiryDate.getMonth(), expiryDate.getDate()));
            let unixTimestamp = Math.floor(dateObject.getTime() / 1000);

            verline.current = chartRef.current.addLineSeries({
                color: "rgba(255, 255, 0, 0.8)",  // Yellow with 80% opacity
                lineWidth: 3,
                lineStyle: 0,  // 0 for solid, 1 for dotted, 2 for dashed
                lastValueVisible: false,
                priceLineVisible: false,
                axisLabelVisible: false,  // This ensures no label appears on the price axis
                crosshairMarkerVisible: true,
                crosshairMarkerRadius: 4,
                crosshairMarkerBorderColor: "rgb(255, 255, 0)",
                crosshairMarkerBackgroundColor: "rgb(255, 255, 0)",
            });

            // Ensure the line is straight
            if (unixTimestamp) {
                const highValue = maxHigh || 100;  // Use 100 if max_high is undefined or null
                const lowValue = minlow || 0;      // Use 0 if minLow is undefined or null


                verline.current.setData([
                    {time: unixTimestamp, value: highValue},
                    {time: unixTimestamp + 6400, value: lowValue}]);


                // Ensure the expiry line is always visible
                chartRef.current.timeScale().setVisibleRange({
                    from: Math.min(chartRef.current.timeScale().getVisibleRange().from, unixTimestamp),
                    to: Math.max(chartRef.current.timeScale().getVisibleRange().to, unixTimestamp),
                });
            } else {
                console.log('no timestamp - ', unixTimestamp);
            }
        }

        const handleResize = () => {
            chartRef.current.applyOptions({
                width: chartContainerRef.current.clientWidth,
            });
            chartRef.current.timeScale().fitContent();
        };

        window.addEventListener("resize", handleResize);

        fetchData(ticker, intervalTime, ema, rsi);

        return () => {
            window.removeEventListener("resize", handleResize);
            chartRef.current.remove();
        };
    }, [ticker, intervalTime, ema, rsi, expiry]); // Update dependencies as needed

    // New useEffect for call price line
    useEffect(() => {
        if (!seriesRef.current || !chartRef.current) return;

        if (strategy === 'Collar' || strategy === 'Covered Call') {
            // Remove existing call price line if it exists
            if (callPriceLineRef.current) {
                seriesRef.current.removePriceLine(callPriceLineRef.current);
            }

            // Create new call price line
            callPriceLineRef.current = seriesRef.current.createPriceLine({
                price: parseFloat(call),
                color: "green",
                lineWidth: 3,
                lineStyle: 1,
                axisLabelVisible: true,
                title: "Call",
            });
        }
    }, [call, strategy]);

    // New useEffect for put price line
    useEffect(() => {
        if (!seriesRef.current || !chartRef.current) return;

        if (strategy === 'Collar' || strategy === 'Put Sale') {
            // Remove existing put price line if it exists
            if (putPriceLineRef.current) {
                seriesRef.current.removePriceLine(putPriceLineRef.current);
            }

            // Create new put price line
            putPriceLineRef.current = seriesRef.current.createPriceLine({
                price: parseFloat(put),
                color: "orange",
                lineWidth: 3,
                lineStyle: 1,
                axisLabelVisible: true,
                title: "Put",
            });
        }
    }, [put, strategy]);


    return (
        <div
            style={{display: "flex", flexDirection: "column", alignItems: "center"}}
        >
            {showSettings ? (
                <Row gutter={[16, 16]} style={{marginBottom: "20px"}}>
                    <Col>
                        <label style={{color: "white"}} htmlFor="interval">
                            Interval:{" "}
                        </label>
                        <Select
                            id="interval"
                            defaultValue={intervalTime}
                            onChange={(value) => setIntervalTime(value)}
                            style={{width: 120}}
                        >
                            <Option value="5m">5m</Option>
                            <Option value="15m">15m</Option>
                            <Option value="1h">1h</Option>
                            <Option value="1d">1d</Option>
                            <Option value="1wk">1wk</Option>
                            <Option value="1mo">1mo</Option>
                        </Select>
                    </Col>
                    <Col>
                        <label style={{color: "white"}} htmlFor="ema">
                            EMA:{" "}
                        </label>
                        <Input
                            id="ema"
                            type="number"
                            value={ema}
                            onChange={(e) => setEma(e.target.value)}
                            style={{width: 120}}
                        />
                    </Col>
                    <Col>
                        <label style={{color: "white"}} htmlFor="rsi">
                            RSI:{" "}
                        </label>
                        <Input
                            id="rsi"
                            type="number"
                            value={rsi}
                            onChange={(e) => setRsi(e.target.value)}
                            style={{width: 120}}
                        />
                    </Col>
                    <Col>
                        <label style={{color: "white"}} htmlFor="frequency">
                            Frequency (s):{" "}
                        </label>
                        <Input
                            id="frequency"
                            type="number"
                            value={frequency}
                            onChange={(e) => setFrequency(e.target.value)}
                            style={{width: 120}}
                        />
                    </Col>
                </Row>
            ) : (

                <ChartLegend
                    intervalTime={intervalTime}
                    setIntervalTime={setIntervalTime}
                    indicators={indicators}
                    expiryDate={expiry}
                    call={call}
                    put={put}
                />
            )}
            <div ref={chartContainerRef} style={{width: "100%", height: "400px"}}/>

        </div>
    );
};

export default RealTimeChart;