import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Form, Input, Button, Tag, Space, Rate, Upload, message, Row, Col } from 'antd';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import DraggableModal from '../DraggableModal/DraggableModal';
import './style.css';

const TradeDetailsModal = ({ children, visible, onCancel, trade, onSave, availableTags, ...props }) => {
  const [form] = Form.useForm();
  const [tags, setTags] = useState(trade?.tags || []);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [modalSize, setModalSize] = useState({ width: 1000, height: 600 });
  const resizeRef = useRef(null);

  const handleSave = () => {
    form.validateFields().then((values) => {
      onSave({ ...trade, ...values, tags });
    });
  };

  const handleClose = (removedTag) => {
    const newTags = tags.filter(tag => tag !== removedTag);
    setTags(newTags);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      setTags([...tags, inputValue]);
    }
    setInputVisible(false);
    setInputValue('');
  };

  const uploadProps = {
    name: 'file',
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76', // Replace with your actual upload endpoint
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const handleResize = useCallback((e) => {
    if (resizeRef.current) {
      const { clientX, clientY } = e;
      const { left, top } = resizeRef.current.getBoundingClientRect();
      const newWidth = clientX - left;
      const newHeight = clientY - top;
      setModalSize({ width: newWidth, height: newHeight });
    }
  }, []);

  const handleMouseDown = useCallback((e) => {
    e.preventDefault();
    document.addEventListener('mousemove', handleResize);
    document.addEventListener('mouseup', handleMouseUp);
  }, [handleResize]);

  const handleMouseUp = useCallback(() => {
    document.removeEventListener('mousemove', handleResize);
    document.removeEventListener('mouseup', handleMouseUp);
  }, [handleResize]);

  useEffect(() => {
    return () => {
      document.removeEventListener('mousemove', handleResize);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [handleResize, handleMouseUp]);

  return (
    <DraggableModal
      className="trade-details-modal"
      title={`Trade Details: ${trade?.symbol || ''}`}
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="save" type="primary" onClick={handleSave}>
          Save
        </Button>,
      ]}
      width={modalSize.width}
      {...props}
    >
      <div ref={resizeRef} style={{ width: '100%', height: modalSize.height }} className="resize-container">
        <div className="trade-details-content">
          <Form form={form} initialValues={trade} layout="vertical">
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="symbol" label="Symbol">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="trade_type" label="Trade Type">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name="entry_price" label="Entry Price">
              <Input />
            </Form.Item>
            <Form.Item name="exit_price" label="Exit Price">
              <Input />
            </Form.Item>
            <Form.Item name="quantity" label="Quantity">
              <Input />
            </Form.Item>
            <Form.Item name="trade_result" label="Result">
              <Input />
            </Form.Item>
            <Form.Item name="satisfaction" label="Satisfaction">
              <Rate />
            </Form.Item>
            <Form.Item name="notes" label="Notes">
              <Input.TextArea rows={4} />
            </Form.Item>
            <Form.Item label="Tags">
              <Space wrap>
                {tags.map((tag) => (
                  <Tag key={tag} closable onClose={() => handleClose(tag)}>
                    {tag}
                  </Tag>
                ))}
                {inputVisible && (
                  <Input
                    type="text"
                    size="small"
                    style={{ width: 78 }}
                    value={inputValue}
                    onChange={handleInputChange}
                    onBlur={handleInputConfirm}
                    onPressEnter={handleInputConfirm}
                  />
                )}
                {!inputVisible && (
                  <Tag onClick={showInput} style={{ background: '#fff', borderStyle: 'dashed' }}>
                    <PlusOutlined /> New Tag
                  </Tag>
                )}
              </Space>
            </Form.Item>
            <Form.Item name="screenshots" label="Screenshots">
              <Upload {...uploadProps}>
                <Button icon={<UploadOutlined />}>Upload Screenshot</Button>
              </Upload>
            </Form.Item>
          </Form>
        </div>
        <div className="resize-handle" onMouseDown={handleMouseDown}></div>
      </div>
      {children}
    </DraggableModal>
  );
};

export default TradeDetailsModal;
