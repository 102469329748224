import React from "react";
import "./style.css";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import ReactTooltip from "react-tooltip";

const Services = ({userData, setUserData, setUserToken}) => {
    function gotoAnalyzer() {
        if (userData?.pages?.includes("option_analyzer")) {
            window.location.href = "/option-analyzer";
        }
    }

    const gotoTrainer = () => {
        if (userData?.pages?.includes("option_trainer")) {
            window.location.href = "/option-trainer";
        }
    };

    const gotoTradeJournal = () => {
        if (userData?.pages?.includes("trade_journal")) {
        console.log("Navigating to Trade Journal");
        window.location.href = "/trade-journal";
        }
    };

    return (
        <div>
            <Navbar
                userData={userData}
                setUserData={setUserData}
                setUserToken={setUserToken}
            />
            <div className="services">
                <h1 className="page-title">A look under the hood</h1>
                <p className="subtitle">
                    These apps are associated with varying aspects of trading. You might get a sense of how one thing
                    ties to another in these prototype dashboards. You can see how we interface with the logic layer to
                    create a simple user engagement with a practical use.

                </p>
                <div className="centered-text">
                    Live brokerage order execution is disabled during testing
                </div>
                <div className="services-wrapper">
                    <div
                        className={
                            userData?.pages?.includes("option_analyzer")
                                ? "service"
                                : "service off"
                        }
                        onClick={gotoAnalyzer}
                        data-tip="The Options Strategy Analyzer is an advanced filtering tool "
                    >
                        <div
                            className={
                                userData?.pages?.includes("option_analyzer")
                                    ? "service-background service1"
                                    : "service-background service1 off"
                            }
                        ></div>
                    </div>
                    <div
                        className={
                            userData?.pages?.includes("option_trainer")
                                ? "service"
                                : "service off"
                        }
                        onClick={gotoTrainer}
                        data-tip="We're working on a neat Objective Wizard designed to rapidly teach strategy selection decisions"
                    >
                        <div
                            className={
                                userData?.pages?.includes("option_trainer")
                                    ? "service-background service2"
                                    : "service-background service2 off"
                            }
                        ></div>
                    </div>

                    <div
                        className={
                            userData?.pages?.includes("trade_journal")
                                ? "service"
                                : "service off"
                        }
                        onClick={gotoTradeJournal}
                        data-tip="Access your Trade Journal"
                    >
                        <div
                            className={
                                userData?.pages?.includes("trade_journal")
                                    ? "service-background service3"
                                    : "service-background service3 off"
                            }
                        ></div>
                    </div>


                    <div
                        className="service off"
                        data-tip="We're working on some really cool stuff !"
                    >
                        <div className="service-background service4 off"></div>
                    </div>
                </div>

                <ReactTooltip className="custom-tooltip"/>
            </div>
            <Footer/>
        </div>
    );
};

export default Services;