import React, { useState, useEffect } from 'react';
import './style.css';
import { Select, DatePicker, Table, Card, Spin, message } from 'antd';
import BarChart from '../Charts/BarChart';
import PieChart from '../Charts/PieChart';
import { getInstruments, updateInstrument, deleteInstrument, getTrades } from '../../../API/tradeJournal';

const { RangePicker } = DatePicker;
const { Option } = Select;

const InstrumentView = ({ userToken, refreshTrigger, onRefreshComplete }) => {
  const [instrumentData, setInstrumentData] = useState([]);
  const [selectedInstrument, setSelectedInstrument] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [loading, setLoading] = useState(true);
  const [instruments, setInstruments] = useState([]);
  const [chartMetrics, setChartMetrics] = useState({ x: 'date', y: 'total_gain' });

  useEffect(() => {
    const fetchInstruments = async () => {
      try {
        const data = await getInstruments();
        setInstruments(data);
        if (data.length > 0) {
          setSelectedInstrument(data[0].symbol);
        }
      } catch (error) {
        console.error('Error fetching instruments:', error);
        message.error('Failed to load instruments');
      }
    };
    fetchInstruments();
  }, []);

  useEffect(() => {
    const fetchInstrumentData = async () => {
      if (!selectedInstrument) return;

      try {
        setLoading(true);
        const data = await getTrades(selectedInstrument, dateRange[0], dateRange[1]);
        setInstrumentData(data);
        if (refreshTrigger) {
          onRefreshComplete();
        }
      } catch (error) {
        console.error('Error fetching instrument data:', error);
        message.error('Failed to load instrument data');
      } finally {
        setLoading(false);
      }
    };
    fetchInstrumentData();
  }, [selectedInstrument, dateRange, refreshTrigger, userToken, onRefreshComplete]);

  const handleInstrumentChange = (value) => {
    setSelectedInstrument(value);
  };

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
  };

  const handleMetricChange = (value, axis) => {
    setChartMetrics(prev => ({ ...prev, [axis]: value }));
  };

  const calculateSummary = () => {
    const totalTrades = instrumentData.length;
    const profitableTrades = instrumentData.filter(trade => trade.total_gain > 0).length;
    const totalGain = instrumentData.reduce((sum, trade) => sum + trade.total_gain, 0);
    const winRate = totalTrades > 0 ? (profitableTrades / totalTrades) * 100 : 0;

    return {
      totalTrades,
      profitableTrades,
      totalGain,
      winRate: winRate.toFixed(2)
    };
  };

  const summary = calculateSummary();

  if (loading) {
    return <Spin size="large" />;
  }

  return (
    <div className="instrument-view">
      <h1>Instrument View</h1>

      <div className="controls">
        <Select
          style={{ width: 200 }}
          value={selectedInstrument}
          onChange={handleInstrumentChange}
        >
          {instruments.map(instrument => (
            <Option key={instrument.symbol} value={instrument.symbol}>{instrument.symbol}</Option>
          ))}
        </Select>
        <RangePicker onChange={handleDateRangeChange} />
      </div>

      <div className="summary-cards">
        <Card title="Total Trades">{summary.totalTrades}</Card>
        <Card title="Profitable Trades">{summary.profitableTrades}</Card>
        <Card title="Total Gain/Loss">${summary.totalGain.toFixed(2)}</Card>
        <Card title="Win Rate">{summary.winRate}%</Card>
      </div>

      <div className="charts">
        <BarChart data={instrumentData} xField={chartMetrics.x} yField={chartMetrics.y} />
        <PieChart data={instrumentData} valueField="total_gain" nameField="date" />
        <div className="chart-controls">
          <Select defaultValue={chartMetrics.x} onChange={(value) => handleMetricChange(value, 'x')}>
            <Option value="date">Date</Option>
            <Option value="quantity">Quantity</Option>
            <Option value="price">Price</Option>
          </Select>
          <Select defaultValue={chartMetrics.y} onChange={(value) => handleMetricChange(value, 'y')}>
            <Option value="total_gain">Total Gain</Option>
            <Option value="gain_percent">Gain Percentage</Option>
            <Option value="quantity">Quantity</Option>
          </Select>
        </div>
      </div>

      <Table
        dataSource={instrumentData}
        columns={[
          { title: 'Date', dataIndex: 'date', key: 'date' },
          { title: 'Type', dataIndex: 'type', key: 'type' },
          { title: 'Quantity', dataIndex: 'quantity', key: 'quantity' },
          { title: 'Price', dataIndex: 'price', key: 'price' },
          { title: 'Total Gain', dataIndex: 'total_gain', key: 'total_gain' },
          { title: 'Gain %', dataIndex: 'gain_percent', key: 'gain_percent' },
        ]}
      />
    </div>
  );
};

export default InstrumentView;