import React, {useState, useEffect, useCallback} from 'react';
import {ImportOutlined, PlusOutlined} from "@ant-design/icons";
import {Modal, message} from 'antd';
import {useNavigate} from "react-router-dom";
import {GlobalProvider} from '../../Stores/globals';
import NavMenu from '../../Components/NavMenu';
import PortfolioOverview from './PortfolioOverview';
import OrderBook from './OrderBook';
import StocksJournal from './StocksJournal';
import OptionsJournal from './OptionsJournal';
import InstrumentView from './InstrumentView';
import ImportTrades from './ImportTrades';
import DraggableModal from '../../Components/DraggableModal/DraggableModal';
import ManualEntry from './ManualEntry';
import Dashboard from './Dashboard/Dashboard';
import './style.css';

// Import specific API functions
import {getInstruments, getTrades, importTrades} from '../../API/tradeJournal';

const VIEWS = {
    DASHBOARD: 'dashboard',  // Added new Dashboard view
    PORTFOLIO: 'portfolio',  // Keep existing Portfolio view
    ORDERBOOK: 'orderbook',
    STOCKS: 'stocks',
    OPTIONS: 'options',
    INSTRUMENT: 'instrument'
};

const NavButton = ({onClick, children}) => (
    <button className="trade-journal__nav-button" onClick={onClick}>
        {children}
    </button>
);

const TradeJournal = ({userData, userToken, setUserData, setUserToken}) => {
    const [activeView, setActiveView] = useState('dashboard');
    const [isImportModalVisible, setIsImportModalVisible] = useState(false);
    const [isManualEntryModalVisible, setIsManualEntryModalVisible] = useState(false);
    const [instruments, setInstruments] = useState([]);
    const [trades, setTrades] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const fetchInstruments = useCallback(async () => {
        try {
            const data = await getInstruments();
            setInstruments(data);
        } catch (error) {
            console.error('Error fetching instruments:', error);
            message.error('Failed to fetch instruments. Please try again.');
        }
    }, []);

    const fetchTrades = useCallback(async () => {
        try {
            const data = await getTrades();
            setTrades(data);
        } catch (error) {
            console.error('Error fetching trades:', error);
            message.error('Failed to fetch trades. Please try again.');
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);
            try {
                await fetchInstruments(); // Only fetch instruments initially
            } catch (error) {
                setError('Failed to load data. Please refresh the page.');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [fetchInstruments]);

    const handleImportButtonClick = () => {
        setIsImportModalVisible(true); // Show the import modal
    };

    const handleImportModalCancel = () => {
        setIsImportModalVisible(false); // Close the import modal
    };

    const handleImportComplete = async () => {
        try {
            await fetchTrades();
            setIsImportModalVisible(false);
            message.success('Trades imported successfully');
        } catch (error) {
            console.error('Error fetching trades after import:', error);
            message.error('Import successful, but failed to refresh trades. Please refresh the page.');
        }
    };

    const showManualEntryModal = () => setIsManualEntryModalVisible(true);
    const handleManualEntryModalCancel = () => setIsManualEntryModalVisible(false);

    const handleManualEntryComplete = async () => {
        await fetchTrades();
        handleManualEntryModalCancel();
        message.success('Trade added successfully');
    };

    const navItems = [
        {view: VIEWS.DASHBOARD, label: 'Dashboard'},  // New Dashboard view
        // {view: VIEWS.PORTFOLIO, label: 'Portfolio Overview'},  // Existing Portfolio view
        {view: VIEWS.ORDERBOOK, label: 'Order Book'},
        {view: VIEWS.STOCKS, label: 'Stocks Journal'},
        {view: VIEWS.OPTIONS, label: 'Options Journal'},
    ];

    const renderContent = () => {
        if (loading) return <div>Loading...</div>;
        if (error) return <div>{error}</div>;

        switch (activeView) {
            case 'dashboard':
                return <Dashboard userData={userData} userToken={userToken} trades={trades}/>;
            case 'portfolio':
                return <PortfolioOverview userData={userData} userToken={userToken} trades={trades}/>;
            case 'orderbook':
                return <OrderBook userData={userData} userToken={userToken} trades={trades}/>;
            case 'stocks':
                return <StocksJournal userData={userData} userToken={userToken} trades={trades}
                                      instruments={instruments}/>;
            case 'options':
                return <OptionsJournal userData={userData} userToken={userToken} trades={trades}
                                       instruments={instruments}/>;
            case 'instrument':
                return <InstrumentView userData={userData} userToken={userToken} instruments={instruments}/>;
            default:
                return <Dashboard userData={userData} userToken={userToken} trades={trades}/>;
        }
    };

    return (
        <GlobalProvider>
            <div className="trade-journal">
                <NavMenu userData={userData} setUserData={setUserData} setUserToken={setUserToken}/>
                <header className="trade-journal__header">
                    <h1>Trade Journal</h1>
                </header>
                <nav className="trade-journal__nav">
                    {navItems.map(item => (
                        <NavButton key={item.view} onClick={() => setActiveView(item.view)}>
                            {item.label}
                        </NavButton>
                    ))}
                    <NavButton onClick={showManualEntryModal} className="manual-trade-btn">
                        <PlusOutlined/> Manual Trade
                    </NavButton>
                    <NavButton onClick={handleImportButtonClick} className="import-trades-btn">
                        <ImportOutlined/> Import Trades
                    </NavButton>
                </nav>
                <main className="trade-journal_content">
                    {renderContent()}
                </main>
                <Modal
                    className="import-trades-modal"
                    title="Import Trades"
                    open={isImportModalVisible}
                    onCancel={handleImportModalCancel}
                    footer={null}
                    width={600}
                >
                    <ImportTrades
                        userData={userData}
                        userToken={userToken}
                        onClose={handleImportModalCancel}
                        onImportComplete={handleImportComplete}
                        isVisible={isImportModalVisible}
                    />
                </Modal>
                <DraggableModal
                    title="Manual Trade Entry"
                    visible={isManualEntryModalVisible}
                    onCancel={handleManualEntryModalCancel}
                    footer={null}
                    width={800}
                >
                    <ManualEntry onClose={handleManualEntryModalCancel} onComplete={handleManualEntryComplete}/>
                </DraggableModal>
            </div>
        </GlobalProvider>
    );
};

export default TradeJournal;