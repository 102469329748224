import React from "react";
import {Wizard, Step, Controls} from "react-decision-tree-flow";
import "../style.css";

const handleStartOver = (navigateToStep1) => {
    if (navigateToStep1) {
        navigateToStep1(); // Navigates directly to step1
    } else {
        console.error('Failed to navigate back to step1');
    }
};

const tree = {
    step1: ["step2Income", "step2Risk", "step2Speculation"],
    step2Income: ["step3IncomeYes", "step3IncomeNo"],
    step3IncomeYes: ["step4IncomeYesSell", "step4IncomeYesNoSell"],
    step4IncomeYesSell: ["coveredCalls", "step5IncomeYesSellAlternatives"],
    step5IncomeYesSellAlternatives: ["ironCondors", "butterflySpreads"],
    step4IncomeYesNoSell: ["incomeYesNoSellAlternatives"],
    step5IncomeYesNoSellAlternatives: ["calendarSpreads", "diagonals"],
    step3IncomeNo: ["step4IncomeNoBuy", "step4IncomeNoNoBuy"],
    step4IncomeNoBuy: ["cashSecuredPuts"],
    step4IncomeNoNoBuy: ["nakedPuts"],
    coveredCalls: ["step1"],
    ironCondors: ["step1"],
    butterflySpreads: ["step1"],
    incomeYesNoSellAlternatives: ["calendarSpreads", "diagonals"],
    calendarSpreads: ["step1"],
    diagonals: ["step1"],
    cashSecuredPuts: ["step1"],
    nakedPuts: ["step1"],
    step2Risk: ["step3RiskYes", "step3RiskNo"],
    step3RiskYes: ["step4RiskYesDecline", "step4RiskNoDecline"],
    step4RiskYesDecline: ["protectivePuts", "step5RiskYesDeclineAlternatives"],
    step5RiskYesDeclineAlternatives: ["collars", "syntheticPositions"],
    protectivePuts: ["step1"],
    collars: ["step1"],
    syntheticPositions: ["step1"],
    step4RiskNoDecline: ["riskAlternatives"],
    riskAlternatives: ["step1"],
    step3RiskNo: ["step4RiskNoShort", "step4RiskNoNoShort"],
    step4RiskNoShort: ["protectiveCalls"],
    step4RiskNoNoShort: ["riskAdjustments"],
    protectiveCalls: ["step1"],
    riskAdjustments: ["step1"],
    step2Speculation: ["step3SpeculationYes", "step3SpeculationNo"],
    step3SpeculationYes: ["step4SpeculationRise", "step4SpeculationFall"],
    step4SpeculationRise: ["longCalls"],
    step4SpeculationFall: ["longPuts"],
    longCalls: ["step1"],
    longPuts: ["step1"],
    step3SpeculationNo: ["step4SpeculationVolatile", "step4SpeculationNoVolatile"],
    step4SpeculationVolatile: ["straddles", "step5SpeculationVolatileAlternatives"],
    step5SpeculationVolatileAlternatives: ["ironButterflies"],
    straddles: ["step1"],
    ironButterflies: ["step1"],
    step4SpeculationNoVolatile: ["speculationAlternatives"],
    speculationAlternatives: ["step1"],
    // Add final review step
    keyRules: ["step1"]
};


const WizardComponent = () => (
    <Wizard tree={tree} first="step1">
        <Step name="step1">
            <div className="wizard-container" style={{marginTop: '10px'}}>


                <h2>There are 3 basic reasons to enter a trade, what's yours?</h2>
                <div className="options-container">
                    <Controls>
                        {({destinations: {step2Income, step2Risk, step2Speculation}}) => (
                            <div className="btn-container-column">
                                <button onClick={step2Income} className="option-button">
                                    Income Generation
                                </button>
                                <button onClick={step2Risk} className="option-button">
                                    Risk Management
                                </button>
                                <button onClick={step2Speculation} className="option-button">
                                    Capital Appreciation
                                </button>
                            </div>
                        )}
                    </Controls>
                </div>
            </div>
        </Step>
        {/* Income Generation Steps */}
        <Step name="step2Income">
            <div className="wizard-container">
                <h2>Do you own at least 100 shares of the stock?</h2>
                <div className="options-container">
                    <Controls>
                        {({destinations: {step3IncomeYes, step3IncomeNo}}) => (
                            <div className="btn-container">
                                <button onClick={step3IncomeYes} className="option-button">
                                    Yes
                                </button>
                                <button onClick={step3IncomeNo} className="option-button">
                                    No
                                </button>
                            </div>
                        )}
                    </Controls>
                </div>
            </div>
        </Step>
        <Step name="step3IncomeYes">
            <div className="wizard-container">
                <h2>Is there a price that you would be willing to take a profit and sell them?</h2>
                <div className="options-container">
                    <Controls>
                        {({destinations: {step4IncomeYesSell, step4IncomeYesNoSell}}) => (
                            <div className="btn-container">
                                <button onClick={step4IncomeYesSell} className="option-button">
                                    Yes
                                </button>
                                <button onClick={step4IncomeYesNoSell} className="option-button">
                                    No
                                </button>
                            </div>
                        )}
                    </Controls>
                </div>
            </div>
        </Step>
        <Step name="step4IncomeYesSell">
            <div className="wizard-container">
                <h2>Recommend: Covered Calls</h2>
                <p>
                    <strong>Objective:</strong> Generate additional income from existing stock positions.<br/>
                    <strong>Example:</strong> You own 100 shares of XYZ stock, currently trading at $50. You sell a call
                    option with a strike price of $55, expecting the stock price to remain below $55 by expiration.<br/>
                    <strong>Rule of Thumb:</strong> Covered calls are a conservative strategy used to generate income
                    from a stock you already own. You sell the call option, collecting the premium, with the
                    understanding that you may need to sell your stock at the strike price if the option is
                    exercised.<br/>
                    <strong>Downside Risk:</strong> If the stock price rises significantly above the strike price, you
                    will be required to sell your shares at the strike price, potentially missing out on further gains.
                    However, you keep the premium received.<br/>
                    <strong>Impact of ITM:</strong> If the stock price is above the strike price at expiration
                    (in-the-money), the option is likely to be exercised, and you will have to sell your shares at the
                    strike price. You gain the premium and any difference between your purchase price and the strike
                    price, but you miss out on further potential gains.
                </p>

                <Controls>
                    {({destinations: {step5IncomeYesSellAlternatives, step1}}) => (
                        <div className="btn-container">
                            <button onClick={step5IncomeYesSellAlternatives} className="option-button">
                                Explore Alternatives
                            </button>
                            <button onClick={step1} className="option-button">
                                Start Over
                            </button>
                        </div>
                    )}
                </Controls>


            </div>
        </Step>
        <Step name="step5IncomeYesSellAlternatives">
            <div className="wizard-container">
                <h2>Recommend: Iron Condors and Butterfly Spreads</h2>
                <Controls>
                    {({destinations: {ironCondors, butterflySpreads, step1}}) => (
                        <div className="btn-container">
                            <button onClick={ironCondors} className="option-button">
                                Iron Condors
                            </button>
                            <button onClick={butterflySpreads} className="option-button">
                                Butterfly Spreads
                            </button>
                            <button onClick={step1} className="option-button">
                                Start Over
                            </button>
                        </div>
                    )}
                </Controls>
            </div>
        </Step>
        <Step name="ironCondors">
            <div className="wizard-container">
                <h2>Recommend: Iron Condors</h2>
                <p>
                    <strong>Objective:</strong> Profit from low volatility in the underlying stock.<br/>
                    <strong>Example:</strong> Sell a call spread and a put spread on XYZ stock, both centered around a
                    strike price of $50, expecting the stock price to stay near $50 by expiration.<br/>
                    <strong>Rule of Thumb:</strong> Iron Condors are ideal when you expect the stock to trade within a
                    narrow range, allowing you to collect premiums from both the call and put spreads.<br/>
                    <strong>Downside Risk:</strong> If the stock price moves significantly in either direction, you may
                    incur losses on one or both spreads, though the maximum loss is limited.<br/>
                    <strong>Impact of ITM:</strong> If one of the spreads becomes ITM, the profit potential is reduced,
                    and you may need to manage the position to avoid assignment.
                </p>
                <Controls>
                    {({destinations: {step1}}) => (
                        <button onClick={step1} className="option-button">
                            Start Over
                        </button>
                    )}
                </Controls>
            </div>
        </Step>
        <Step name="butterflySpreads">
            <div className="wizard-container">
                <h2>Recommend: Butterfly Spreads</h2>
                <p>
                    <strong>Objective:</strong> Profit from low volatility and a stock staying near a specific
                    price.<br/>
                    <strong>Example:</strong> Buy one call option with a strike price of $45, sell two call options with
                    a strike price of $50, and buy one call option with a strike price of $55, forming a butterfly
                    spread around the $50 strike price.<br/>
                    <strong>Rule of Thumb:</strong> Butterfly Spreads are most effective when you expect minimal
                    movement in the underlying stock and want to limit both risk and reward.<br/>
                    <strong>Downside Risk:</strong> The maximum loss is limited to the net premium paid for the spread,
                    but if the stock moves significantly away from the center strike price, the spread can expire
                    worthless.<br/>
                    <strong>Impact of ITM:</strong> If the stock price stays near the middle strike price at expiration,
                    the butterfly spread can provide a high reward-to-risk ratio, with potential gains if it stays ITM.
                </p>
                <Controls>
                    {({destinations: {step1}}) => (
                        <button onClick={step1} className="option-button">
                            Start Over
                        </button>
                    )}
                </Controls>
            </div>
        </Step>


        <Step name="step4IncomeYesNoSell">
            <div className="wizard-container">
                <h2>Recommend: Consider other strategies</h2>
                <p>
                    Consider other strategies such as selling covered calls on other stocks or exploring alternative
                    income-generating strategies.
                </p>
                <Controls>
                    {({destinations: {step5IncomeYesNoSellAlternatives, step1}}) => (
                        <div className="btn-container">
                            <button onClick={step5IncomeYesNoSellAlternatives} className="option-button">
                                Explore Alternatives
                            </button>
                            <button onClick={step1} className="option-button">
                                Start Over
                            </button>
                        </div>
                    )}
                </Controls>
            </div>
        </Step>
        <Step name="step5IncomeYesNoSellAlternatives">
            <div className="wizard-container">
                <h2>Recommend: Calendar Spreads and Diagonals</h2>
                <Controls>
                    {({destinations: {calendarSpreads, diagonals, step1}}) => (
                        <div className="btn-container">
                            <button onClick={calendarSpreads} className="option-button">
                                Calendar Spreads
                            </button>
                            <button onClick={diagonals} className="option-button">
                                Diagonals
                            </button>
                            <button onClick={step1} className="option-button">
                                Start Over
                            </button>
                        </div>
                    )}
                </Controls>
            </div>
        </Step>
        <Step name="step3IncomeNo">
            <div className="wizard-container">
                <h2>Are you willing to purchase shares if the price hits your strike price?</h2>
                <div className="options-container">
                    <Controls>
                        {({destinations: {step4IncomeNoBuy, step4IncomeNoNoBuy}}) => (
                            <div className="btn-container">
                                <button onClick={step4IncomeNoBuy} className="option-button">
                                    Yes
                                </button>
                                <button onClick={step4IncomeNoNoBuy} className="option-button">
                                    No
                                </button>
                            </div>
                        )}
                    </Controls>
                </div>
            </div>
        </Step>
        <Step name="step4IncomeNoBuy">
            <div className="wizard-container">
                <h2>Recommend: Cash-Secured Puts</h2>
                <p>
                    By selling cash-secured puts, you agree to buy the underlying stock at the strike price if the
                    option is exercised. This generates income through the premium received. If the stock price drops,
                    you may have to purchase the shares at the strike price.
                </p>
                <Controls>
                    {({destinations: {step1}}) => (
                        <button onClick={step1} className="option-button">
                            Start Over
                        </button>
                    )}
                </Controls>
            </div>
        </Step>
        <Step name="step4IncomeNoNoBuy">
            <div className="wizard-container">
                <h2>Recommend: Naked Puts</h2>
                <p>
                    By selling puts without securing the cash to purchase the stock, you generate income through the
                    premium received. However, this involves higher risk, as you may have to buy the stock at the strike
                    price without having set aside the cash.
                </p>
                <Controls>
                    {({destinations: {step1}}) => (
                        <button onClick={step1} className="option-button">
                            Start Over
                        </button>
                    )}
                </Controls>
            </div>
        </Step>
        {/* Risk Management Steps */}
        <Step name="step2Risk">
            <div className="wizard-container">
                <h2>Do you own shares of the underlying stock?</h2>
                <div className="options-container">
                    <Controls>
                        {({destinations: {step3RiskYes, step3RiskNo}}) => (
                            <div className="btn-container">
                                <button onClick={step3RiskYes} className="option-button">
                                    Yes
                                </button>
                                <button onClick={step3RiskNo} className="option-button">
                                    No
                                </button>
                            </div>
                        )}
                    </Controls>
                </div>
            </div>
        </Step>
        <Step name="step3RiskYes">
            <div className="wizard-container">
                <h2>Are you concerned about a significant decline in the stock's price?</h2>
                <div className="options-container">
                    <Controls>
                        {({destinations: {step4RiskYesDecline, step4RiskNoDecline}}) => (
                            <div className="btn-container">
                                <button onClick={step4RiskYesDecline} className="option-button">
                                    Yes
                                </button>
                                <button onClick={step4RiskNoDecline} className="option-button">
                                    No
                                </button>
                            </div>
                        )}
                    </Controls>
                </div>
            </div>
        </Step>
        <Step name="step4RiskYesDecline">
            <div className="wizard-container">
                <h2>Recommend: Protective Puts</h2>
                <p>
                    By buying protective puts, you insure against a significant decline in the stock's price. You pay a
                    premium for the put option, which gives you the right to sell your shares at the strike price,
                    limiting your potential losses.
                </p>
                <Controls>
                    {({destinations: {step5RiskYesDeclineAlternatives, step1}}) => (
                        <div className="btn-container">
                            <button onClick={step5RiskYesDeclineAlternatives} className="option-button">
                                Explore Alternatives
                            </button>
                            <button onClick={step1} className="option-button">
                                Start Over
                            </button>
                        </div>
                    )}
                </Controls>
            </div>
        </Step>
        <Step name="step5RiskYesDeclineAlternatives">
            <div className="wizard-container">
                <h2>Recommend: Collars and Synthetic Positions</h2>
                <Controls>
                    {({destinations: {collars, syntheticPositions, step1}}) => (
                        <div className="btn-container">
                            <button onClick={collars} className="option-button">
                                Collars
                            </button>
                            <button onClick={syntheticPositions} className="option-button">
                                Synthetic Positions
                            </button>
                            <button onClick={step1} className="option-button">
                                Start Over
                            </button>
                        </div>
                    )}
                </Controls>
            </div>
        </Step>
        <Step name="step4RiskNoDecline">
            <div className="wizard-container">
                <h2>Recommend: Consider other strategies</h2>
                <p>
                    Consider other strategies such as collars or stop-loss orders to address your specific concerns.
                </p>
                <Controls>
                    {({destinations: {step1}}) => (
                        <button onClick={step1} className="option-button">
                            Start Over
                        </button>
                    )}
                </Controls>
            </div>
        </Step>
        <Step name="step3RiskNo">
            <div className="wizard-container">
                <h2>Do you want to protect against a potential increase in the price of a stock you have shorted?</h2>
                <div className="options-container">
                    <Controls>
                        {({destinations: {step4RiskNoShort, step4RiskNoNoShort}}) => (
                            <div className="btn-container">
                                <button onClick={step4RiskNoShort} className="option-button">
                                    Yes
                                </button>
                                <button onClick={step4RiskNoNoShort} className="option-button">
                                    No
                                </button>
                            </div>
                        )}
                    </Controls>
                </div>
            </div>
        </Step>
        <Step name="step4RiskNoShort">
            <div className="wizard-container">
                <h2>Recommend: Protective Calls</h2>
                <p>
                    By buying protective calls, you insure against a significant increase in the stock's price. You pay
                    a premium for the call option, which gives you the right to buy shares at the strike price, limiting
                    your potential losses.
                </p>
                <Controls>
                    {({destinations: {step1}}) => (
                        <button onClick={step1} className="option-button">
                            Start Over
                        </button>
                    )}
                </Controls>
            </div>
        </Step>
        <Step name="step4RiskNoNoShort">
            <div className="wizard-container">
                <h2>Recommend: Consider other strategies</h2>
                <p>
                    Consider other strategies such as stop-loss orders or adjusting your short position to manage risk.
                </p>
                <Controls>
                    {({destinations: {step1}}) => (
                        <button onClick={step1} className="option-button">
                            Start Over
                        </button>
                    )}
                </Controls>
            </div>
        </Step>
        {/* Capital Appreciation */}
        <Step name="step2Speculation">
            <div className="wizard-container">
                <h2>Do you expect the price of the underlying stock to move significantly?</h2>
                <div className="options-container">
                    <Controls>
                        {({destinations: {step3SpeculationYes, step3SpeculationNo}}) => (
                            <div className="btn-container">
                                <button onClick={step3SpeculationYes} className="option-button">
                                    Yes
                                </button>
                                <button onClick={step3SpeculationNo} className="option-button">
                                    No
                                </button>
                            </div>
                        )}
                    </Controls>
                </div>
            </div>
        </Step>
        <Step name="step3SpeculationYes">
            <div className="wizard-container">
                <h2>Do you expect the price to rise or fall?</h2>
                <div className="options-container">
                    <Controls>
                        {({destinations: {step4SpeculationRise, step4SpeculationFall}}) => (
                            <div className="btn-container">
                                <button onClick={step4SpeculationRise} className="option-button">
                                    Rise
                                </button>
                                <button onClick={step4SpeculationFall} className="option-button">
                                    Fall
                                </button>
                            </div>
                        )}
                    </Controls>
                </div>
            </div>
        </Step>
        <Step name="step4SpeculationRise">
            <div className="wizard-container">
                <h2>Recommend: Long Calls</h2>
                <p>
                    By buying long calls, you speculate on the stock price rising. You pay a premium for the call
                    option, which gives you the right to buy the stock at the strike price. Profit potential is
                    unlimited, while losses are limited to the premium paid.
                </p>
                <Controls>
                    {({destinations: {step1}}) => (
                        <button onClick={step1} className="option-button">
                            Start Over
                        </button>
                    )}
                </Controls>
            </div>
        </Step>
        <Step name="step4SpeculationFall">
            <div className="wizard-container">
                <h2>Recommend: Long Puts</h2>
                <p>
                    By buying long puts, you speculate on the stock price falling. You pay a premium for the put option,
                    which gives you the right to sell the stock at the strike price. Profit potential is significant,
                    while losses are limited to the premium paid.
                </p>
                <Controls>
                    {({destinations: {step1}}) => (
                        <button onClick={step1} className="option-button">
                            Start Over
                        </button>
                    )}
                </Controls>
            </div>
        </Step>
        <Step name="step3SpeculationNo">
            <div className="wizard-container">
                <h2>Do you expect the stock to be volatile without a clear direction?</h2>
                <div className="options-container">
                    <Controls>
                        {({destinations: {step4SpeculationVolatile, step4SpeculationNoVolatile}}) => (
                            <div className="btn-container">
                                <button onClick={step4SpeculationVolatile} className="option-button">
                                    Yes
                                </button>
                                <button onClick={step4SpeculationNoVolatile} className="option-button">
                                    No
                                </button>
                            </div>
                        )}
                    </Controls>
                </div>
            </div>
        </Step>
        <Step name="step4SpeculationVolatile">
            <div className="wizard-container">
                <h2>Recommend: Straddles</h2>
                <p>
                    By buying straddles, you speculate on significant price movement in either direction. You buy both a
                    call and a put option at the same strike price and expiration date, profiting from large moves up or
                    down. Profit potential is significant, but you need a substantial price movement to cover the
                    combined premiums paid.
                </p>
                <Controls>
                    {({destinations: {step5SpeculationVolatileAlternatives, step1}}) => (
                        <div className="btn-container">
                            <button onClick={step5SpeculationVolatileAlternatives} className="option-button">
                                Explore Alternatives
                            </button>
                            <button onClick={step1} className="option-button">
                                Start Over
                            </button>
                        </div>
                    )}
                </Controls>
            </div>
        </Step>
        <Step name="step5SpeculationVolatileAlternatives">
            <div className="wizard-container">
                <h2>Recommend: Iron Butterflies</h2>
                <Controls>
                    {({destinations: {ironButterflies, step1}}) => (
                        <div className="btn-container">
                            <button onClick={ironButterflies} className="option-button">
                                Iron Butterflies
                            </button>
                            <button onClick={step1} className="option-button">
                                Start Over
                            </button>
                        </div>
                    )}
                </Controls>
            </div>
        </Step>
        <Step name="step4SpeculationNoVolatile">
            <div className="wizard-container">
                <h2>Recommend: Consider other strategies</h2>
                <p>
                    Consider other strategies or reevaluate your speculation outlook, perhaps looking at lower
                    volatility strategies or non-directional trades like iron condors.
                </p>
                <Controls>
                    {({destinations: {step1}}) => (
                        <button onClick={step1} className="option-button">
                            Start Over
                        </button>
                    )}
                </Controls>
            </div>
        </Step>
        {/* Final Review Step */}
        <Step name="keyRules">
            <div className="wizard-container">
                <h2>Key Rules of Thumb for Options Trading</h2>
                <p>
                    Risk Management: Only risk what you can afford to lose.<br/>
                    Time Decay Awareness: Options lose value over time.<br/>
                    Volatility Considerations: High volatility increases premiums.<br/>
                    Liquidity Matters: Trade liquid options to ensure you can enter and exit positions easily.<br/>
                    Understand Assignment Risk: Be prepared for assignment if you sell options.
                </p>
                <Controls>
                    {({destinations: {step1}}) => (
                        <button onClick={step1} className="option-button">
                            Start Over
                        </button>
                    )}
                </Controls>
            </div>
        </Step>
    </Wizard>
);

export default WizardComponent;