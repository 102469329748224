import React, { useState, useEffect } from 'react';
import { Upload, message, Select, Button, Modal, Progress, Checkbox } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useGlobalContext } from '../../../Stores/globals';
import { importTrades, getImportStatus } from '../../../API/tradeJournal';
import './style.css';

const { Dragger } = Upload;
const { Option } = Select;

const ImportTrades = ({ onClose, onImportComplete, isVisible }) => {
  const { brokers } = useGlobalContext();
  const [broker, setBroker] = useState('');
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadMfePrices, setUploadMfePrices] = useState(false);
  const [importing, setImporting] = useState(false);
  const [importStatus, setImportStatus] = useState('');

  useEffect(() => {
    if (!isVisible) {
      resetForm();
    }
  }, [isVisible]);

  const handleFileChange = (info) => {
    const { status } = info.file;
    
    if (status !== 'uploading') {
      setFile(info.file);
    }
    if (status === 'done') {
      message.success(`${info.file.name} file selected successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file selection failed.`);
    }
  };

  const handleImport = async () => {
    if (!file || !broker) {
      message.error('Please select a broker and upload a file.');
      return;
    }

    try {
      setImporting(true);
      setUploadProgress(0);
      const formData = new FormData();
      formData.append('file', file.originFileObj || file);
      formData.append('broker_name', broker);
      formData.append('uploadMfePrices', uploadMfePrices.toString());

      console.log('FormData contents:');
      for (let [key, value] of formData.entries()) {
        console.log(key, value instanceof File ? `File: ${value.name}` : value);
      }

      await importTrades(formData, handleProgressUpdate, handleImportComplete);

    } catch (error) {
      console.error('Import error:', error);
      message.error(error.response?.data?.message || error.message || 'Failed to import trades. Please try again.');
      setImporting(false);
      setUploadProgress(0);
    }
  };

  const handleProgressUpdate = (statusData) => {
    if (statusData.progress) {
      setUploadProgress(statusData.progress);
    }
    setImportStatus(statusData.message);

    if (statusData.status === 'Completed') {
      message.success(statusData.message || 'Import completed successfully');
    } else if (statusData.status === 'Error') {
      message.error(statusData.message || 'Import failed');
    }
  };

  const handleImportComplete = () => {
    setImporting(false);
    setUploadProgress(100);
    if (onImportComplete) onImportComplete();
    resetForm();
    onClose();
  };

  const pollImportStatus = async (importId) => {
    const pollInterval = setInterval(async () => {
      try {
        const statusData = await getImportStatus(importId);
        handleProgressUpdate(statusData);

        if (statusData.status === 'Completed') {
          clearInterval(pollInterval);
          setImporting(false);
          setUploadProgress(100);
          if (onImportComplete) onImportComplete();
          resetForm();
          onClose();
        } else if (statusData.status === 'Error') {
          clearInterval(pollInterval);
          setImporting(false);
          setUploadProgress(0);
          message.error(statusData.message || 'Import failed');
        }
      } catch (error) {
        console.error('Error polling import status:', error);
        clearInterval(pollInterval);
        setImporting(false);
        setUploadProgress(0);
        message.error('Failed to get import status. Please check your imports manually.');
      }
    }, 2000); // Poll every 2 seconds
  };

  const handleClearAll = () => {
    Modal.confirm({
      title: 'Are you sure you want to clear all uploaded files?',
      content: 'This action cannot be undone.',
      onOk() {
        resetForm();
        message.success('All uploaded files have been cleared.');
      },
    });
  };

  const resetForm = () => {
    setBroker('');
    setFile(null);
    setUploadMfePrices(false);
    setImportStatus('');
  };

  return (
    <div className="import-trades-content">
      Select the Broker you got your file from
      <div className="broker-select">
        <Select
          style={{ width: '200px' }}
          placeholder="Select Broker for upload"
          onChange={(value) => {
            setBroker(value);
          }}
          value={broker}
          className="custom-select"
        >
          {brokers.map((b) => (
            <Option key={b.value} value={b.value}>
              {b.label}
            </Option>
          ))}
        </Select>
      </div>

      <div className="file-upload">
        <Dragger
          name="file"
          multiple={false}
          onChange={handleFileChange}
          beforeUpload={() => false}
          accept=".csv,.xlsx,.xls"
          fileList={[]}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          <p className="ant-upload-hint">
            Support for a single CSV or Excel file upload.
          </p>
        </Dragger>
      </div>
      {file && (
        <div className="selected-file-display">
          Selected file: {file.name}
        </div>
      )}
      <div className="upload-options">
        <Checkbox
          checked={uploadMfePrices}
          onChange={(e) => setUploadMfePrices(e.target.checked)}
        >
          Upload MFE Prices
        </Checkbox>
      </div>
      <div className="action-buttons">
        <div className="left-buttons">
          <Button
            type="primary"
            onClick={handleImport}
            disabled={!file || !broker || importing}
            loading={importing}
          >
            {importing ? 'Importing...' : 'Import Trades'}
          </Button>
        </div>
        <div className="right-buttons">
          <Button 
            onClick={handleClearAll} 
            disabled={!file || importing}
            danger
          >
            Clear All
          </Button>
          <Button onClick={onClose} disabled={importing}>
            Cancel
          </Button>
        </div>
      </div>
      {uploadProgress > 0 && (
        <div className="upload-progress">
          <Progress percent={uploadProgress} status={uploadProgress === 100 ? "success" : "active"} />
          <p>{importStatus}</p>
        </div>
      )}
    </div>
  );
};

export default ImportTrades;