import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { DatePicker, Button, Card, Table, Spin, message } from 'antd';
import moment from 'moment';
import { getTrades } from '../../../API/tradeJournal';
import './style.css';

const { RangePicker } = DatePicker;

const OptionsJournal = ({ userToken, refreshTrigger, onRefreshComplete }) => {
  const [journalData, setJournalData] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [loading, setLoading] = useState(true);

  const fetchJournalData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getTrades();
      
      if (!Array.isArray(response)) {
        throw new Error('Invalid response format');
      }

      setJournalData(response);
      
      if (refreshTrigger) {
        onRefreshComplete();
      }
    } catch (error) {
      console.error('Error fetching trades:', error);
      message.error('Failed to load trades');
    } finally {
      setLoading(false);
    }
  }, [refreshTrigger, onRefreshComplete]);

  useEffect(() => {
    fetchJournalData();
  }, [fetchJournalData, userToken]);

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
  };

  const handleDatePreset = (preset) => {
    const today = moment();
    let start, end;
    switch (preset) {
      case 'today':
        start = today.startOf('day');
        end = today.endOf('day');
        break;
      case 'thisWeek':
        start = today.startOf('week');
        end = today.endOf('week');
        break;
      case 'thisMonth':
        start = today.startOf('month');
        end = today.endOf('month');
        break;
      case 'thisQuarter':
        start = today.startOf('quarter');
        end = today.endOf('quarter');
        break;
      case 'thisYear':
        start = today.startOf('year');
        end = today.endOf('year');
        break;
      default:
        start = null;
        end = null;
    }
    setDateRange([start, end]);
  };

  const filteredTrades = useMemo(() => {
    return journalData
      .filter(trade => trade.asset_type === 'option')
      .filter(trade => {
        if (!dateRange[0] || !dateRange[1]) return true;
        const tradeDate = moment(trade.trade_entry_time);
        return tradeDate.isBetween(dateRange[0], dateRange[1], null, '[]');
      });
  }, [journalData, dateRange]);

  const summaryMetrics = useMemo(() => {
    const totalGainLoss = filteredTrades.reduce((sum, trade) => {
      if (trade.exit_price && trade.price) {
        const tradeGainLoss = ((trade.exit_price - trade.price) * trade.quantity) - 
                              (parseFloat(trade.fees) + parseFloat(trade.commission) + parseFloat(trade.slippage));
        return sum + tradeGainLoss;
      }
      return sum;
    }, 0);
    const winningTrades = filteredTrades.filter(trade => trade.trade_result === 'win');
    const winRate = filteredTrades.length > 0 ? (winningTrades.length / filteredTrades.length) * 100 : 0;

    return {
      totalGainLoss: totalGainLoss.toFixed(2),
      winRate: winRate.toFixed(2),
      tradeCount: filteredTrades.length
    };
  }, [filteredTrades]);

  const columns = [
    { title: 'ID', dataIndex: 'trade_id', key: 'trade_id' },
    { title: 'Symbol', dataIndex: 'symbol', key: 'symbol' },
    { title: 'Broker', dataIndex: 'broker', key: 'broker' },
    { title: 'Option Type', dataIndex: 'option_type', key: 'option_type' },
    { title: 'Strike Price', dataIndex: 'strike_price', key: 'strike_price', render: (value) => value ? `$${parseFloat(value).toFixed(2)}` : 'N/A' },
    { title: 'Expiration Date', dataIndex: 'expiration_date', key: 'expiration_date', render: (text) => text ? moment(text).format('YYYY-MM-DD') : 'N/A' },
    { title: 'Strategy', dataIndex: 'strategy', key: 'strategy' },
    { title: 'Trade Type', dataIndex: 'trade_type', key: 'trade_type' },
    { 
      title: 'Entry Price', 
      dataIndex: 'price', 
      key: 'price',
      render: (value) => value ? `$${parseFloat(value).toFixed(2)}` : 'N/A'
    },
    { 
      title: 'Exit Price', 
      dataIndex: 'exit_price', 
      key: 'exit_price',
      render: (value) => value ? `$${parseFloat(value).toFixed(2)}` : 'N/A'
    },
    { 
      title: 'Entry Time', 
      dataIndex: 'trade_entry_time', 
      key: 'trade_entry_time',
      render: (text) => text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : 'N/A'
    },
    { 
      title: 'Exit Time', 
      dataIndex: 'exit_date', 
      key: 'exit_date',
      render: (text) => text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : 'N/A'
    },
    { title: 'Result', dataIndex: 'trade_result', key: 'trade_result' },
  ];

  if (loading) {
    return <Spin size="large" />;
  }

  return (
    <div className="options-journal">
      <h1>Options Journal</h1>
      
      <div className="date-range-picker">
        <RangePicker 
          onChange={handleDateRangeChange} 
          value={dateRange}
          className="custom-range-picker"
          style={{
            backgroundColor: 'var(--bg-color)',
            borderColor: 'var(--input-border)',
          }}
          inputStyle={{
            color: 'var(--text-color)',
          }}
        />
        <Button onClick={() => handleDatePreset('today')}>Today</Button>
        <Button onClick={() => handleDatePreset('thisWeek')}>This Week</Button>
        <Button onClick={() => handleDatePreset('thisMonth')}>This Month</Button>
        <Button onClick={() => handleDatePreset('thisQuarter')}>This Quarter</Button>
        <Button onClick={() => handleDatePreset('thisYear')}>This Year</Button>
        <Button onClick={() => setDateRange([null, null])}>Clear</Button>
      </div>

      <div className="summary-cards">
        <Card title="Total Gain/Loss">
          <span className={summaryMetrics.totalGainLoss >= 0 ? 'positive' : 'negative'}>
            ${Number(summaryMetrics.totalGainLoss || 0).toFixed(2)}
          </span>
        </Card>
        <Card title="Win Rate">
          {summaryMetrics.winRate}%
        </Card>
        <Card title="Trade Count">
          {summaryMetrics.tradeCount}
        </Card>
      </div>

      <Table
        dataSource={filteredTrades}
        columns={columns}
        rowKey="trade_id"
        className="options-journal-table"
        pagination={{
          pageSize: 10,
          showSizeChanger: true,
          showQuickJumper: true,
        }}
      />
    </div>
  );
};

export default OptionsJournal;
