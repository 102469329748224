import axios from "axios";
import { getRefreshToken, getToken } from "../../Utils/UpdateUsersState";

const login = async (email, password) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/token/`,
      {
        email,
        password,
      }
    );

    return res;
  } catch (e) {
    console.log(e);
  }
}

const refresh = async (token) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/token/refresh/`,
        { refresh: token },
        {
        }
      );
      return res;
    } catch (e) {
      console.log(e);
    }
};

// Add this new function
const register = async (email, password, name) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/register/`,
      {
        email,
        password,
        name,
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export { login, refresh, register };