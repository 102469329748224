import "./App.css";
import AppRoutes from "./Routes/AppRoutes";
import { ConfigProvider } from "antd";

function App() {
  return (
    <div className="App">
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#0bbf4a",
            borderRadius: "4px",
            boxShadow: "none",
          },
          components: {
            Input: {
              paddingBlock: 8,
              paddingInline: 12,
            },
            Table: {
              cellPaddingBlock: 10,
              headerBg: "#121212",
              colorBgContainer: "#191919",
              borderColor: "#272727",
              colorText: "#ffffffb2",
              stickyScrollBarBg: "#303030",
            },
          },
        }}
      >
        <AppRoutes />
      </ConfigProvider>
    </div>
  );
}

export default App;
