import React, { createContext, useState, useContext } from 'react';

const GlobalContext = createContext(undefined);

export const GlobalProvider = ({ children }) => {
  const [tradesData, setTradesData] = useState([]);
  const [timeZoneTrade, setTimeZoneTrade] = useState('America/New_York');
  const [futureContractsJson, setFutureContractsJson] = useState([]);
  const [futuresTradeStationFees, setFuturesTradeStationFees] = useState([]);
  const [futuresTradovateFees, setFuturesTradovateFees] = useState([]);
  const [selectedTradovateTier, setSelectedTradovateTier] = useState('');
  const [currentUser] = useState({ apis: [] });

  const brokers = [
    
    { value: 'Schwab', label: 'Schwab' },  // Added Schwab entry
    { value: 'TD Ameritrade', label: 'TD Ameritrade' },
    { value: 'Alpaca-ANO', label: 'Alpaca-ANO' },  // Updated Alpaca entry
    { value: 'Alpaca-ALP', label: 'Alpaca-ALP' },  // New Alpaca entry
    { value: 'phantom', label: 'Phantom' },
    { value: 'TradeStation', label: 'TradeStation' },
    { value: 'MetaTrader5', label: 'MetaTrader 5' },
    { value: 'TradeZero', label: 'TradeZero' },
    { value: 'FundTraders', label: 'FundTraders' },
    { value: 'Generic CSV', label: 'Generic CSV' },  // Commented out as discussed
  ];

  return (
    <GlobalContext.Provider
      value={{
        tradesData,
        setTradesData,
        timeZoneTrade,
        setTimeZoneTrade,
        futureContractsJson,
        setFutureContractsJson,
        futuresTradeStationFees,
        setFuturesTradeStationFees,
        futuresTradovateFees,
        setFuturesTradovateFees,
        selectedTradovateTier,
        setSelectedTradovateTier,
        currentUser,
        brokers,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (context === undefined) {
    throw new Error('useGlobalContext must be used within a GlobalProvider');
  }
  return context;
};