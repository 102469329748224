import React, {useMemo, useState, useEffect} from 'react';
import {Card, Col, Row, DatePicker, Spin, Alert, Table, message} from 'antd';
import PieChart from '../../../Components/Charts/PieChart/PieChart';
import DoubleLineChart from '../../../Components/Charts/DoubleLineChart/DoubleLineChart';
import moment from 'moment';
import {getTrades} from '../../../API/tradeJournal';
import './style.css';

const {RangePicker} = DatePicker;

const Dashboard = () => {
    const [allTrades, setAllTrades] = useState([]);
    const [dateRange, setDateRange] = useState([null, null]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [initialBalance, setInitialBalance] = useState(() => {
        const storedBalance = localStorage.getItem('initialBalance');
        return storedBalance ? parseFloat(storedBalance) : 0;
    });

    useEffect(() => {
        if (initialBalance === 0) {
            const balance = prompt("Please enter your initial account balance:");
            const parsedBalance = parseFloat(balance);
            if (!isNaN(parsedBalance) && parsedBalance > 0) {
                setInitialBalance(parsedBalance);
                localStorage.setItem('initialBalance', parsedBalance.toString());
            } else {
                message.error("Invalid balance entered. Please try again.");
            }
        }
    }, [initialBalance]);

    useEffect(() => {
        const fetchTrades = async () => {
            try {
                const result = await getTrades();
                setAllTrades(result);
                console.log('Fetched trades:', result.data);
            } catch (err) {
                setError('Failed to fetch trades.');
            } finally {
                setLoading(false);
            }
        };
        fetchTrades();
    }, []);

    const calculateSummary = (trades = []) => {
        const netWorth = trades.reduce((sum, trade) => sum + (trade.quantity * trade.price) || 0, 0);
        const unrealizedNet = trades.reduce((sum, trade) => {
            const currentValue = trade.exit_price || trade.price;
            return sum + ((currentValue - trade.price) * trade.quantity);
        }, 0);
        return {netWorth, unrealizedNet};
    };

    const filteredTrades = useMemo(() => {
        if (dateRange[0] && dateRange[1]) {
            return allTrades.filter(trade => {
                const tradeDate = moment(trade.trade_entry_time);
                return tradeDate.isBetween(dateRange[0], dateRange[1], 'days', '[]');
            });
        }
        return allTrades || [];
    }, [allTrades, dateRange]);

    const {netWorth, unrealizedNet} = calculateSummary(filteredTrades);

    const openPositions = (filteredTrades || []).filter(trade => trade.is_open);
    const openOptions = (filteredTrades || []).filter(trade => trade.asset_type === 'option' && trade.is_open);

    const columnsPositions = [
        {
            title: 'Symbol',
            dataIndex: 'symbol',
            key: 'symbol',
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Entry Price',
            dataIndex: 'price',
            key: 'price',
        },
        {
            title: 'Current Price',
            dataIndex: 'exit_price',
            key: 'exit_price',
        },
    ];

    const columnsOptions = [
        {
            title: 'Symbol',
            dataIndex: 'symbol',
            key: 'symbol',
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Entry Price',
            dataIndex: 'price',
            key: 'price',
        },
        {
            title: 'Option Type',
            dataIndex: 'trade_type',
            key: 'trade_type',
        },
    ];

    const calculatePortfolioValue = (trades, initialBalance) => {
        let balance = initialBalance;
        const portfolioByDate = {};

        const sortedTrades = [...trades].sort((a, b) => moment(a.trade_entry_time).diff(moment(b.trade_entry_time)));

        sortedTrades.forEach(trade => {
            const entryDate = moment(trade.trade_entry_time).format('YYYY-MM-DD');

            if (!portfolioByDate[entryDate]) {
                portfolioByDate[entryDate] = {
                    date: entryDate,
                    value: balance,
                    dailyProfitLoss: 0,
                    numTrades: 0,
                    winningTrades: 0,
                    losingTrades: 0,
                    largestWin: 0,
                    largestLoss: 0,
                    totalFees: 0,
                    tradedSymbols: {},
                    openPositions: 0,
                    realizedPL: 0,
                    unrealizedPL: 0
                };
            }

            portfolioByDate[entryDate].numTrades++;
            portfolioByDate[entryDate].totalFees += parseFloat(trade.fees) + parseFloat(trade.commission) + parseFloat(trade.slippage);
            portfolioByDate[entryDate].tradedSymbols[trade.symbol] = (portfolioByDate[entryDate].tradedSymbols[trade.symbol] || 0) + 1;

            const tradePL = trade.exit_price ?
                (parseFloat(trade.exit_price) - parseFloat(trade.price)) * parseFloat(trade.quantity) * (trade.trade_type === 'long' ? 1 : -1) :
                0;

            if (trade.exit_date) {
                portfolioByDate[entryDate].realizedPL += tradePL;
                if (tradePL > 0) {
                    portfolioByDate[entryDate].winningTrades++;
                    portfolioByDate[entryDate].largestWin = Math.max(portfolioByDate[entryDate].largestWin, tradePL);
                } else if (tradePL < 0) {
                    portfolioByDate[entryDate].losingTrades++;
                    portfolioByDate[entryDate].largestLoss = Math.min(portfolioByDate[entryDate].largestLoss, tradePL);
                }
            } else {
                portfolioByDate[entryDate].unrealizedPL += tradePL;
                portfolioByDate[entryDate].openPositions++;
            }

            balance += tradePL - portfolioByDate[entryDate].totalFees;
            portfolioByDate[entryDate].value = balance;
            portfolioByDate[entryDate].dailyProfitLoss += tradePL - portfolioByDate[entryDate].totalFees;
        });

        Object.values(portfolioByDate).forEach(day => {
            day.mostTradedSymbol = Object.entries(day.tradedSymbols).reduce((a, b) => a[1] > b[1] ? a : b)[0];
        });

        return Object.values(portfolioByDate).sort((a, b) => moment(a.date).diff(moment(b.date)));
    };

    const portfolioValues = calculatePortfolioValue(filteredTrades, initialBalance);

    const xAxisData = portfolioValues.map(item => item.date);
    const yAxisData = portfolioValues.map(item => ({
        value: item.value,
        dailyProfitLoss: item.dailyProfitLoss,
        numTrades: item.numTrades,
        winningTrades: item.winningTrades,
        losingTrades: item.losingTrades,
        largestWin: item.largestWin,
        largestLoss: item.largestLoss,
        totalFees: item.totalFees,
        mostTradedSymbol: item.mostTradedSymbol,
        openPositions: item.openPositions,
        realizedPL: item.realizedPL,
        unrealizedPL: item.unrealizedPL
    }));

    const minValue = Math.min(...yAxisData.map(item => item.value));
    const maxValue = Math.max(...yAxisData.map(item => item.value));
    const range = maxValue - minValue;
    const buffer = range * 0.05;
    let yAxisMin = Math.floor((minValue - buffer) / 100) * 100;
    let yAxisMax = Math.ceil((maxValue + buffer) / 100) * 100;
    const minRange = Math.max(range, 1000);
    const tickCount = 5;
    const tickSize = Math.ceil(minRange / (tickCount - 1) / 100) * 100;
    yAxisMax = Math.max(yAxisMax, yAxisMin + (tickCount - 1) * tickSize);

    const summaryData = useMemo(() => {
        if (filteredTrades.length === 0) return null;

        const totalPL = filteredTrades.reduce((sum, trade) => sum + (trade.exit_price - trade.price) * trade.quantity, 0);
        const winningTrades = filteredTrades.filter(trade => trade.exit_price > trade.price);
        const winRate = (winningTrades.length / filteredTrades.length * 100).toFixed(2);

        const tradeCounts = filteredTrades.reduce((acc, trade) => {
            acc[trade.symbol] = (acc[trade.symbol] || 0) + 1;
            return acc;
        }, {});
        const mostTradedSymbol = Object.entries(tradeCounts).sort((a, b) => b[1] - a[1])[0][0];

        const largestWin = Math.max(...filteredTrades.map(trade => (trade.exit_price - trade.price) * trade.quantity));
        const largestLoss = Math.min(...filteredTrades.map(trade => (trade.exit_price - trade.price) * trade.quantity));

        const avgDuration = filteredTrades.reduce((sum, trade) => {
            return sum + moment(trade.exit_date).diff(moment(trade.trade_entry_time), 'hours');
        }, 0) / filteredTrades.length;

        return {
            totalPL,
            winRate,
            mostTradedSymbol,
            largestWin,
            largestLoss,
            avgDuration: avgDuration.toFixed(2),
            totalTrades: filteredTrades.length,
        };
    }, [filteredTrades]);

    if (loading) {
        return <Spin size="large"/>;
    }

    if (error) {
        return <Alert message={error} type="error"/>;
    }

    return (
        <div className="dashboard">
            <h1>Trading Dashboard</h1>
            <Row gutter={16}>
                <Col span={12}>
                    <Card className="chart-card">
                        <h2>Portfolio Value Over Time</h2>
                        <DoubleLineChart
                            grossData={yAxisData}
                            netData={[]}
                            xAxisData={xAxisData}
                            yAxisMin={yAxisMin}
                            yAxisMax={yAxisMax}
                            yAxisTickSize={tickSize}
                            title="Portfolio Value Over Time"
                        />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card className="chart-card">
                        <h2>Trade Results</h2>
                        <div className="inner-cards-container">
                            <div className="pie-chart-card">
                                <div className="pie-chart-container">
                                    <PieChart
                                        pieChartData={[
                                            { value: filteredTrades.filter(trade => trade.trade_result === 'win').length, name: 'Wins' },
                                            { value: filteredTrades.filter(trade => trade.trade_result === 'fail').length, name: 'Losses' }
                                        ]}
                                    />
                                </div>
                            </div>
                            <div className="summary-card">
                                <div className="summary-card-container">
                                    <h3>Summary</h3>
                                    {summaryData ? (
                                        <ul>
                                            <li>Total P/L: ${summaryData.totalPL.toFixed(2)}</li>
                                            <li>Win Rate: {summaryData.winRate}%</li>
                                            <li>Most Traded: {summaryData.mostTradedSymbol}</li>
                                            <li>Largest Win: ${summaryData.largestWin.toFixed(2)}</li>
                                            <li>Largest Loss: ${summaryData.largestLoss.toFixed(2)}</li>
                                            <li>Total Trades: {summaryData.totalTrades}</li>
                                        </ul>
                                    ) : (
                                        <p>No trade data available for the selected period.</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <Card title="Open Positions">
                        <Table dataSource={openPositions} columns={columnsPositions} rowKey="id"/>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <Card title="Open Options">
                        <Table dataSource={openOptions} columns={columnsOptions} rowKey="id"/>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default Dashboard;