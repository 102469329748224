import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { decryptText, encryptText } from "../Utils/Encryption";
import axios from "axios";
import { refresh } from "../API/auth";
import updateUserStates, {
  getRefreshToken,
  handleLogout,
} from "../Utils/UpdateUsersState";
import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";

const AppRoutes = () => {
  const [userData, setUserData] = useState({
    username: "",
    role: "",
    id: 0,
    pages: [],
  });
  const [userToken, setUserToken] = useState({ access: "", refresh: "" });
  const [loading, setLoading] = useState(true);

  const refreshAccessToken = async (token) => {
    let my = token?.refresh;
    if (!my) {
      my = getRefreshToken();
    }
    const res = await refresh(my);
    if (res?.status === 200) {
      const localStorageItems = localStorage;
      if (localStorageItems.length !== 0) {
        for (let key in localStorageItems) {
          localStorage.removeItem(key);
        }
      }
      localStorage.setItem(
        encryptText("token"),
        encryptText(
          JSON.stringify({
            access: res?.data?.access,
            refresh: res?.data?.refresh,
          })
        )
      );
      localStorage.setItem(
        encryptText("user"),
        encryptText(
          JSON.stringify({
            id: res?.data?.id,
            username: res?.data?.username,
            role: res?.data?.role,
            name: res?.data?.name,
            pages: res?.data?.pages,
          })
        )
      );
      updateUserStates(setUserData, setUserToken);
      return res?.data?.access;
    } else {
      handleLogout(setUserData, setUserToken);
      window.location.href = "/login";
      return null;
    }
  };

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (
        error?.response?.status === 401 &&
        error.config.url !==
          `${process.env.REACT_APP_BACKEND_URL}/api/token/refresh/` &&
        error.config.url !== `${process.env.REACT_APP_BACKEND_URL}/api/token/`
      ) {
        const newToken = await refreshAccessToken();

        if (newToken) {
          error.config.headers["Authorization"] = "Bearer " + newToken;
          return axios(error.config);
        } else {
          handleLogout(setUserData, setUserToken);
          window.location.href = "/login";
        }
      } else if (
        error?.response?.status === 403 &&
        error.config.url.includes("/api/analyzer/")
      ) {
        handleLogout(setUserData, setUserToken);
        window.location.href = "/login";
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    const initializeUserState = async () => {
      setLoading(true);
      const updatedStates = updateUserStates(setUserData, setUserToken);
      if (
        updatedStates?.token?.access &&
        updatedStates?.token?.refresh &&
        updatedStates?.user?.username &&
        loading
      ) {
        await refreshAccessToken(updatedStates.token);
      }
      setLoading(false);
    };

    if (
      userData.role === "" &&
      userToken.access === "" &&
      userToken.refresh === "" &&
      loading
    ) {
      initializeUserState();
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <div>
      {loading ? null : (
        <BrowserRouter>
          {userData?.username ? (
            <PrivateRoutes
              setUserData={setUserData}
              setUserToken={setUserToken}
              userData={userData}
              userToken={userToken}
            />
          ) : (
            <PublicRoutes
              setUserData={setUserData}
              setUserToken={setUserToken}
            />
          )}
        </BrowserRouter>
      )}
    </div>
  );
};

export default AppRoutes;
