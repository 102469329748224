import React, {useState} from "react";
import "./style.css";
import {
    EyeOutlined,
    EyeInvisibleOutlined,
    LockOutlined,
    UserOutlined,
} from "@ant-design/icons";
import {Button, Form, Input, notification} from "antd";
import {Link, useNavigate} from "react-router-dom";
import {IoMdArrowBack} from "react-icons/io";
import {login} from "../../API/auth";
import {encryptText} from "../../Utils/Encryption";
import updateUserStates from "../../Utils/UpdateUsersState";

const Login = ({setUserData, setUserToken}) => {
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const onFinish = async (values) => {
        setLoading(true);
        const loginData = await login(values?.username, values?.password);

        if (loginData?.status === 200) {
            localStorage.setItem(
                encryptText("token"),
                encryptText(
                    JSON.stringify({
                        access: loginData?.data?.access,
                        refresh: loginData?.data?.refresh,
                    })
                )
            );
            localStorage.setItem(
                encryptText("user"),
                encryptText(
                    JSON.stringify({
                        id: loginData?.data?.id,
                        username: loginData?.data?.username,
                        role: loginData?.data?.role,
                        name: loginData?.data?.name,
                        pages: loginData?.data?.pages,
                    })
                )
            );

            updateUserStates(setUserData, setUserToken);
            navigate("/services");
        } else
            notification.error({
                message: "Invalid login details",
                description: "Kindly provide correct username and password",
            });
        setLoading(false);
    };

    const formLayout = {
        labelCol: {
            span: 24,
        },
        wrapperCol: {
            span: 24,
        },
    };

    return (
        <div className="login">
            <div className="back-home">
                <div
                    className="back-home-text"
                    onClick={() => {
                        navigate("/home");
                    }}
                >
                    <IoMdArrowBack/>
                    <div className="back-home-t">Back to Home</div>
                </div>
            </div>
            <div className="login-card">
                <div className="title">

                    <h1>Welcome to Anothen Analytics Lab</h1>
                </div>
                <div className="explanation">
                    <p>
                        This portal is designed for invited users of Anothen Analytics. By logging in, you will gain
                        access to prototypes we have moved into the UX phase. If you have received credentials, please
                        use them to proceed. Thank you and enjoy your visit. We would love to hear your thoughts.
                    </p>
                </div>
                <div className="form">
                    <Form
                        name="normal_login"
                        className="login-form"
                        requiredMark={false}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        {...formLayout}
                    >
                        <Form.Item
                            label={<span className="username-label">Email</span>}
                            name="username"
                            rules={[
                                {
                                    message: "Please input your Email!",
                                    required: true,
                                },
                            ]}
                        >
                            <Input
                                prefix={<UserOutlined className="site-form-item-icon"/>}
                                placeholder="Email"
                            />
                        </Form.Item>
                        <Form.Item
                            label={<span className="password-label">Password</span>}
                            name="password"
                            rules={[
                                {
                                    message: "Please input your Password!",
                                    required: true,
                                },
                            ]}
                        >
                            <Input.Password
                                prefix={<LockOutlined className="site-form-item-icon"/>}
                                placeholder="Password"
                                iconRender={(visible) =>
                                    visible ? (
                                        <EyeInvisibleOutlined onClick={togglePasswordVisibility}/>
                                    ) : (
                                        <EyeOutlined onClick={togglePasswordVisibility}/>
                                    )
                                }
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="login-form-button"
                                loading={loading}
                            >
                                Login
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
                 <div className="register-link">
          <p>
            Don't have an account? <Link to="/register">Request Access Here</Link>
          </p>
        </div>
            </div>
        </div>
    );
};

export default Login;