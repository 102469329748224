

// frontend/src/Pages/Registration/index.js

import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { UserOutlined, LockOutlined, MailOutlined } from '@ant-design/icons';
import { useNavigate, Link } from 'react-router-dom';
import { register } from '../../API/auth';

import { encryptText } from '../../Utils/Encryption';
import updateUserStates from '../../Utils/UpdateUsersState';
import './style.css';

const Registration = ({ setUserData, setUserToken }) => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const onFinish = async (values) => {
        setLoading(true);
        try {
            const response = await register(values.email, values.password, values.name);

            if (response?.status === 201) {
                localStorage.setItem(
                    encryptText("token"),
                    encryptText(JSON.stringify({
                        access: response?.data?.access,
                        refresh: response?.data?.refresh,
                    }))
                );
                localStorage.setItem(
                    encryptText("user"),
                    encryptText(JSON.stringify({
                        id: response?.data?.id,
                        username: response?.data?.username,
                        role: response?.data?.role,
                        name: response?.data?.name,
                        pages: response?.data?.pages,
                    }))
                );

                updateUserStates(setUserData, setUserToken);
                message.success('Registration successful!');
                navigate("/services");
            } else {
                throw new Error(response?.data?.message || 'Registration failed');
            }
        } catch (error) {
            message.error(error.message || 'An error occurred during registration');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="registration-container">
            <div className="registration-card">
                <div className="title">
                    <h1>Register for Anothen Analytics Lab</h1>
                </div>
                <div className="explanation">
                    <p>
                        Request access to our prototypes and analytics tools.
                        Please fill in the details below to create your account.
                    </p>
                </div>
                <div className="form">
                    <Form
                        name="register"
                        onFinish={onFinish}
                        layout="vertical"
                    >
                        <Form.Item
                            name="email"
                            rules={[
                                {required: true, message: 'Please input your email!'},
                                {type: 'email', message: 'Please enter a valid email!'}
                            ]}
                        >
                            <Input prefix={<MailOutlined/>} placeholder="Email"/>
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[{required: true, message: 'Please input your password!'}]}
                        >
                            <Input.Password prefix={<LockOutlined/>} placeholder="Password"/>
                        </Form.Item>
                        <Form.Item
                            name="confirm"
                            dependencies={['password']}
                            rules={[
                                {required: true, message: 'Please confirm your password!'},
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('The two passwords do not match!'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password prefix={<LockOutlined/>} placeholder="Confirm Password"/>
                        </Form.Item>
                        <Form.Item
                            name="name"
                            rules={[{required: true, message: 'Please input your name!'}]}
                        >
                            <Input prefix={<UserOutlined/>} placeholder="Full Name"/>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={loading}>
                                Register
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
                <div className="login-link">
                    <p>
                        Already have an account? <Link to="/login">Login here</Link>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Registration;